export default {
  methods: {
    /* Title: convertDateToString
     *
     * Description: converts a timestamp into a date string
     *
     */
    convertDateToString(convert_date: string): string {
      if (convert_date === null) {
        return "-";
      } else {
        const date = new Date(Date.parse(convert_date));
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return day + "." + month + "." + year;
      }
    },

    /* Title: convertDateToHoursString
     *
     * Description: converts a timestamp into an hour string
     *
     */
    convertDateToHoursString(convert_date: string): string {
      if (convert_date === null) {
        return "-";
      } else {
        const date = new Date(Date.parse(convert_date));
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return hours + ":" + minutes + ":" + seconds;
      }
    },

    /* Title: convertMillisToHoursString
     *
     * Description: converts miliseconds to hours
     *
     */
    convertMillisToHoursString(milliseconds: number): string {
      const hours = milliseconds / 3600000;
      return Number.parseFloat(hours.toString()).toFixed(1);
    },

    /* Title: convertMillisToMinutesString
     *
     * Description: converts miliseconds to minutes
     *
     */
    convertMillisToMinutesString(milliseconds: number): string {
      const minutes = milliseconds / 60000;
      return Number.parseFloat(minutes.toString()).toFixed(1);
    },

    /* Title: notificationTypeToText
     *
     * Description: converts the notification type into a string
     *
     */
    notificationTypeToText(
      notification_type: "success" | "info" | "warning" | "error"
    ) {
      switch (notification_type) {
        case "success":
          return "Erfolg";
        case "info":
          return "Info";
        case "warning":
          return "Warnung";
        case "error":
          return "Fehler";
        default:
          break;
      }
    },

    /* Title: convertMinutesToHoursMinutes
     *
     * Description: minutes into hours and remaining minutes
     *
     */
    convertMinutesToHoursMinutes(minutes: number) {
      return (
        (minutes / 60).toFixed(0) + "h " + (minutes % 60).toFixed(0) + "min"
      );
    },
  },
};
