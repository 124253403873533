import axios from "axios";
import { ObjectId } from "mongodb";
import { Sensor, SensorStatus } from "../../types/sensor";

const url = "api/sensor/";

const state = {
  sensors: [],
};

const getters = {
  /**
   * Title: allSensors()
   *
   * Description: Returns all Sensors as an array
   */
  allSensors: (state: any) => state.sensors,

  /**
   * Title: specificSensor()
   *
   * Description: Returns a specific Sensor
   *
   * @param {ObjectId} sensor_id the id of the sensor that should be returned
   *
   */
  specificSensor: (state: any) => (sensor_id: ObjectId) => {
    return state.sensors.find((sensor: Sensor) => sensor._id === sensor_id);
  },
};

const actions = {
  /**
   * Title: fetchSensors()
   *
   * Route: GET /sensor/
   * Description: Fetches data for all Sensors
   */

  async fetchSensors({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setSensors", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Sensoren konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newSensor()
   *
   * Route: Post /sensor/
   * Description: Adds new Sensor to the Database
   *
   * @param {String} name the name of new sensor
   * @param {String} description the description of the new sensor
   * @param {String} location the location of the new sensor
   * @param {String} icon the icon of the new sensor
   * @param {Number} operating_max_critical the critical max operating range
   * @param {Number} operating_min_critical the critical min operating range
   * @param {Number} operating_max_warn the max operating range
   * @param {Number} operating_min_warn the min operating range
   * @param {String} unit the unit used by the sensor
   *
   * Info: Every other attribute of a sensor is beeing added by the API and returned as a response which then is pushed to the state
   */
  async newSensor(
    { commit, dispatch }: any,
    {
      name,
      description,
      location,
      icon,
      operating_range_max,
      operating_range_min,
      optimal_operating_range_max,
      optimal_operating_range_min,
      unit,
      type
    }: {
      name: string;
      description: string;
      location: string;
      icon: string;
      operating_range_max: number;
      operating_range_min: number;
      optimal_operating_range_max: number;
      optimal_operating_range_min: number;
      unit: string;
      type: string
    }
  ) {
    try {
      const response = await axios.post(url, {
        name,
        description,
        location,
        icon,
        operating_range_max,
        operating_range_min,
        optimal_operating_range_max,
        optimal_operating_range_min,
        unit,
        type,
      });
      commit("addSensor", response.data);
      dispatch(
        "newNotification",
        {
          text: "Sensor gespeichert",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
      dispatch("fetchOverviewNavigationTree");
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Sensor konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: deleteSensor()
   *
   * Route: Delete /sensor/id
   * Description: Deletes a Sensor from the Database
   *
   * @param {Sensor} sensor the sensor that is being deleted
   *
   */
  async deleteSensor({ commit, dispatch }: any, sensor: Sensor) {
    try {
      const delete_url = url + sensor._id;
      const response = await axios.delete(delete_url);
      commit("removeSensor", sensor);
      dispatch(
        "newNotification",
        {
          text: "Sensor wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Sensor konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editSensor()
   *
   * Route: Put /sensor/id
   * Description: Pushes the new state of a sensor to the databas
   *
   * @param {Sensor} sensor the new state sensor that is being edited
   * @param {Number} sensor_index the index of the machin within the state array, transmitted as the new state of the sensor would not allow to find the index here as new state is different from the old state
   *
   * Info: Every other attribute of a sensor is beeing added by the API and returned as a response which then is pushed to the state
   */
  async editSensor(
    { commit, dispatch }: any,
    { sensor, sensor_index }: { sensor: Sensor; sensor_index: number }
  ) {
    try {
      console.log(sensor);
      const put_url = url + sensor._id;
      const response = await axios.put(put_url, sensor);
      const data = response.data;
      console.log(data);
      console.log(sensor_index);
      commit("updateSensor", { sensor: data, sensor_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
      dispatch("fetchOverviewNavigationTree");
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: startSensor()
   *
   * Description: trys to start a Sensor
   *
   * @param {ObjectId} sensor_id the id of the sensor
   */
  async startSensor({ dispatch }: any, { sensor_id }: { sensor_id: ObjectId }) {
    try {
      const patch_url = url + "command/" + sensor_id;
      const response = await axios.patch(patch_url, { command: "start" });
      dispatch(
        "newNotification",
        {
          text: "Sensor wurde gestartet.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Sensor konnte nicht gestartet werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: stopSensor()
   *
   * Description: trys to stop a Sensor
   *
   * @param {ObjectId} sensor_id the id of the sensor
   */
  async stopSensor({ dispatch }: any, { sensor_id }: { sensor_id: ObjectId }) {
    try {
      console.log("stop")
      const patch_url = url + "command/" + sensor_id;
      const response = await axios.patch(patch_url, { command: "stop" });
      console.log(response)
      dispatch(
        "newNotification",
        {
          text: "Sensor wurde gestopt.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Sensor konnte nicht gestopt werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: mqttSensorStatusUpdate()
   *
   * Description: Updates the Status of a sensor received by mqtt
   *
   * @param {Sensor} sensor_id the id of the sensor
   * @param {Number} sensor_status the status of the sensor
   */
  mqttSensorStatusUpdate(
    { commit, getters }: any,
    {
      sensor_id,
      sensor_status,
    }: { sensor_id: ObjectId; sensor_status: SensorStatus }
  ) {
    const sensor_index = getters.allSensors.indexOf(
      getters.specificSensor(sensor_id)
    );
    commit("updateSensorStatus", { sensor_index, sensor_status });
  },

  /**
   * Title: mqttSensorValueUpdate()
   *
   * Description: Updates the Value of a sensor received by mqtt
   *
   * @param {Sensor} sensor_id the id of the sensor
   * @param {Number} sensor_value the value of the sensor
   */
  mqttSensorValueUpdate(
    { commit, getters }: any,
    {
      sensor_id,
      sensor_value,
    }: { sensor_id: ObjectId; sensor_value: SensorStatus }
  ) {
    const sensor_index = getters.allSensors.indexOf(
      getters.specificSensor(sensor_id)
    );
    commit("updateSensorValue", { sensor_index, sensor_value });
  },

  /**
   * Title: mqttSensorValueUpdate()
   *
   * Description: Updates the Value of a sensor received by mqtt
   *
   * @param {Sensor} sensor_id the id of the sensor
   * @param {Number} sensor_value the value of the sensor
   */
  mqttSensorOperatingTimeUpdate(
    { commit, getters }: any,
    {
      sensor_id,
      operating_time,
    }: { sensor_id: ObjectId; operating_time: number }
  ) {
    const sensor_index = getters.allSensors.indexOf(
      getters.specificSensor(sensor_id)
    );
    commit("updateSensorOperatingTime", { sensor_index, operating_time });
  },

  /**
   * Title: mqttSensorValueUpdate()
   *
   * Description: Updates the Value of a sensor received by mqtt
   *
   * @param {Sensor} sensor_id the id of the sensor
   * @param {Number} assigned the value of the sensor
   */
  mqttSensorAssignedUpdate(
    { commit, getters }: any,
    {
      sensor_id,
      assigned,
    }: { sensor_id: ObjectId; assigned: boolean }
  ) {
    const sensor_index = getters.allSensors.indexOf(
      getters.specificSensor(sensor_id)
    );
    commit("updateSensorAssigned", { sensor_index, assigned });
  },
};

const mutations = {
  setSensors: (state: any, sensors: Sensor[]) => (state.sensors = sensors),
  addSensor: (state: any, sensor: Sensor) => state.sensors.push(sensor),
  removeSensor: (state: any, sensor: Sensor) =>
    state.sensors.splice(state.sensors.indexOf(sensor), 1),
  updateSensor: (state: any, payload: any) =>
    (state.sensors[payload.sensor_index] = payload.sensor),
  updateSensorStatus: (state: any, payload: any) =>
    (state.sensors[payload.sensor_index].current_status =
      payload.sensor_status.valueOf()),
  updateSensorValue: (state: any, payload: any) => (
    (state.sensors[payload.sensor_index].current_reading =
      payload.sensor_value.valueOf()),
    (state.sensors[payload.sensor_index].operating_time += 0.16)
  ),
  updateSensorOperatingTime: (state: any, payload: any) =>
    (state.sensors[payload.sensor_index].operating_time =
      payload.operating_time.valueOf()),
  updateSensorAssigned: (state: any, payload: any) =>
    (state.sensors[payload.sensor_index].assigned = payload.assigned.valueOf()),
  addToSensorMaintenanceHistory: (state: any, payload: any) =>
    state.sensors[payload.sensor_index].maintenance_history.push(
      payload.maintenance_id
    ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
