import axios from "axios";
const overview_navigation_tree_url = "api/navigation/";

const state = {
  // Navigation Icons used across the frontend
  navigation_icons: {
    digital_twin: "mdi-content-copy",
    factory: "mdi-factory",
    machine_group: "mdi-group",
    machine: "mdi-robot-industrial",
    machine_type: "mdi-application-cog",
    sensor: "mdi-thermometer",
    sensor_type: "mdi-leak",
    user: "mdi-account",
    product_type: "mdi-package",
    home: "mdi-home",
    add: "mdi-plus",
    edit: "mdi-pencil",
    delete: "mdi-delete",
    pw_reset: "mdi-key",
    role: "mdi-account-multiple",
    search: "mdi-magnify",
    remove: "mdi-close",
    material_type: "mdi-circle-edit-outline",
    production_order: "mdi-clipboard-text-outline",
    logout: "mdi-logout",
    information: "mdi-information",
    settings: "mdi-cog",
    favoured: "mdi-star",
    success: "mdi-check-circle",
    warning: "mdi-alert-circle",
    error: "mdi-close-circle",
    info: "mdi-information",
    maintenance: "mdi-wrench",
    maintenance_plan: "mdi-wrench-clock",
    maintenance_type: "mdi-progress-wrench",
    notification: "mdi-bell",
    check: "mdi-check-bold",
    close: "mdi-close",
    assembly: "mdi-package-up",
    extended: "mdi-chevron-down",
    reduced: "mdi-chevron-right",
    sim_results: "mdi-chart-line-variant",
    sim_overview: "mdi-format-list-bulleted",
    dev_tools: "mdi-hammer-wrench",
  },

  // The Navigation Tree of the Overview View
  overview_navigation_tree: [],
};

const getters = {
  /**
   * Title: navigationIcons()
   *
   * Description: Returns all Nvigation Icons as an array
   */
  navigationIcons: (state: any) => state.navigation_icons,

  /**
   * Title: overviewNavigationTree()
   *
   * Description: Returns all OverviewNavigationTree Data
   */
  overviewNavigationTree: (state: any) => state.overview_navigation_tree,
};

const actions = {

  /**
   * Title: fetchOverviewNavigationTree()
   *
   * Route: GET /api/others/navigationDrawer/
   * Description: Fetches the Overview Navigation Tree Data
   */

  async fetchOverviewNavigationTree({ commit, dispatch, rootGetters }: any) {
    try {
      let response = {};
      if (rootGetters.getAppLoginRights.hierachy_level <= 2){
        response = await axios.get(overview_navigation_tree_url + 'factory');
      } else if (rootGetters.getAppLoginRights.hierachy_level === 3){
        response = await axios.get(overview_navigation_tree_url + 'machineGroup');
      } else if (rootGetters.getAppLoginRights.hierachy_level === 4){
        response = await axios.get(overview_navigation_tree_url + 'machine');
      } else if (rootGetters.getAppLoginRights.hierachy_level === 5){
        response = await axios.get(overview_navigation_tree_url + 'sensor');
      }
      commit("setOverviewNavigationTree", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Navigationsbaum konnte nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },
};

const mutations = {
  setOverviewNavigationTree: (state: any, overview_navigation_tree: any) =>
    (state.overview_navigation_tree = overview_navigation_tree),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
