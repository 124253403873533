import axios from "axios";
import { ObjectId } from "mongodb";
import { Factory } from "../../types/factory";

const url = "api/factory/";

const state = {
  factorys: [],
};

const getters = {
  /**
   * Title: allFactorys()
   *
   * Description: Returns all Factorys as an array
   */
  allFactorys: (state: any): Factory[] => state.factorys,

  /**
   * Title: specificFactory()
   *
   * Description: Returns a specific Factory
   *
   * @param {ObjectId} factory_id the id of the factory that should be returned
   *
   */
  specificFactory:
    (state: any) =>
    (factory_id: ObjectId): Factory => {
      return state.factorys.find(
        (factory: Factory) => factory._id === factory_id
      );
    },
};

const actions = {
  /**
   * Title: fetchFactorys()
   *
   * Route: GET /factory
   * Description: Fetches data for all Factorys
   */

  async fetchFactorys({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setFactorys", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Fabriken konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newFactory()
   *
   * Route: Post /factory/
   * Description: Adds new Factory to the Database
   *
   * @param {String} name the name of new factory
   * @param {String} description the description of the new factory
   * @param {String} location the location of the new factory
   * @param {ObjectId} type the type of the new factory
   *
   * Info: Every other attribute of a factory is beeing added by the API and returned as a response which then is pushed to the state
   */
  async newFactory(
    { commit, dispatch }: any,
    {
      name,
      description,
      location,
    }: { name: string; description: string; location: string }
  ) {
    try {
      const response = await axios.post(url, {
        name,
        description,
        location,
        components: {
          machine_groups: [],
          sensors: [],
        },
      });
      commit("addFactory", response.data);
      dispatch(
        "newNotification",
        {
          text: "Fabrik gespeichert",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
      dispatch("fetchOverviewNavigationTree");
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Fabrik konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newFactory()
   *
   * Route: Delete /factory/id
   * Description: Deletes a Factory from the Database
   *
   * @param {Factory} factory the factory that is being deleted
   *
   */
  async deleteFactory({ commit, dispatch }: any, factory: Factory) {
    try {
      // const delete_url = url + factory._id
      // const response = await axios.delete(delete_url);
      commit("removeFactory", factory);
      dispatch(
        "newNotification",
        {
          text: "Fabrik wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Fabrik konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editFactory()
   *
   * Route: Put /factory/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} factory the new state factory that is being edited
   * @param {Number} factory_index the index of the machin within the state array, transmitted as the new state of the factory would not allow to find the index here as new state is different from the old state
   *
   * Info: Every other attribute of a factory is beeing added by the API and returned as a response which then is pushed to the state
   */

  async editFactory(
    { commit, dispatch }: any,
    { factory, factory_index }: { factory: Factory; factory_index: number }
  ) {
    try {
      const put_url = url + factory._id;
      const response = await axios.put(put_url, factory);
      const data = response.data;
      commit("updateFactory", { factory: data, factory_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
      dispatch("fetchOverviewNavigationTree");
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: addMachineGroupToFactory()
   *
   * Route: Put /factory/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} factory_id the id factory that is being edited
   * @param {Number} machinegroup_id the id of the machinegroup that is beeing added
   *
   */

  async addMachineGroupToFactory(
    { commit, dispatch, getters }: any,
    {
      factory_id,
      machinegroup_id,
    }: { factory_id: ObjectId; machinegroup_id: ObjectId }
  ) {
    try {
      const patch_url = url + factory_id + "/addMachineGroup";
      const factory_index = getters.allFactorys.indexOf(
        getters.specificFactory(factory_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: machinegroup_id,
      });
      const data = response.data;
      commit("updateFactory", { factory: data, factory_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: removeMachineGroupToFactory()
   *
   * Route: Put /factory/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} factory_id the id factory that is being edited
   * @param {Number} machinegroup_id the id of the machinegroup that is beeing added
   *
   */

  async removeMachineGroupFromFactory(
    { commit, dispatch, getters }: any,
    {
      factory_id,
      machinegroup_id,
    }: { factory_id: ObjectId; machinegroup_id: ObjectId }
  ) {
    try {
      const patch_url = url + factory_id + "/removeMachineGroup";
      const factory_index = getters.allFactorys.indexOf(
        getters.specificFactory(factory_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: machinegroup_id,
      });
      const data = response.data;
      commit("updateFactory", { factory: data, factory_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: addSensorGroupToFactory()
   *
   * Route: Put /factory/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} factory_id the id factory that is being edited
   * @param {Number} sensor_id the id of the machinegroup that is beeing added
   *
   */

  async addSensorToFactory(
    { commit, dispatch, getters }: any,
    { factory_id, sensor_id }: { factory_id: ObjectId; sensor_id: ObjectId }
  ) {
    try {
      const patch_url = url + factory_id + "/addSensor";
      const factory_index = getters.allFactorys.indexOf(
        getters.specificFactory(factory_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sensor_id,
      });
      const data = response.data;
      commit("updateFactory", { factory: data, factory_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: addSensorGroupToFactory()
   *
   * Route: Put /factory/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} factory_id the id factory that is being edited
   * @param {Number} sensor_id the id of the machinegroup that is beeing added
   *
   */

  async removeSensorFromFactory(
    { commit, dispatch, getters }: any,
    { factory_id, sensor_id }: { factory_id: ObjectId; sensor_id: ObjectId }
  ) {
    try {
      const patch_url = url + factory_id + "/removeSensor";
      const factory_index = getters.allFactorys.indexOf(
        getters.specificFactory(factory_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sensor_id,
      });
      const data = response.data;
      commit("updateFactory", { factory: data, factory_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  mqttFactoryStatusUpdate(
    { commit, getters }: any,
    {
      factory_id,
      current_status,
    }: { factory_id: ObjectId; current_status: number }
  ) {
    const factory_index = getters.allFactorys.indexOf(
      getters.specificFactory(factory_id)
    );
    commit("setFactoryStatus", { factory_index, current_status });
  },

  mqttFactoryCurrentThroughputUpdate(
    { commit, getters }: any,
    {
      factory_id,
      current_throughput,
    }: { factory_id: ObjectId; current_throughput: number }
  ) {
    const factory_index = getters.allFactorys.indexOf(
      getters.specificFactory(factory_id)
    );
    commit("setFactoryCurrentThroughput", {
      factory_index,
      current_throughput,
    });
  },
};

const mutations = {
  setFactorys: (state: any, factorys: Factory[]) => (state.factorys = factorys),
  addFactory: (state: any, factory: Factory) => state.factorys.push(factory),
  removeFactory: (state: any, factory: Factory) =>
    state.factorys.splice(state.factorys.indexOf(factory), 1),
  updateFactory: (state: any, payload: any) =>
    (state.factorys[payload.factory_index] = payload.factory),
  setFactoryStatus: (state: any, payload: any) => (
    state.factorys[payload.factory_index].status_history.push({
      time: new Date().toISOString(),
      value: payload.current_status.valueOf(),
    }),
    (state.factorys[payload.factory_index].current_status =
      payload.current_status.valueOf())
  ),
  setFactoryCurrentThroughput: (state: any, payload: any) => (
    state.factorys[payload.factory_index].throughput_history.push({
      time: new Date().toISOString(),
      value: payload.current_throughput.valueOf(),
    }),
    (state.factorys[payload.factory_index].current_throughput =
      payload.current_throughput.valueOf())
  ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
