import { User } from "@/types/user";
import router from "@/router";
const state = {
  app_loading_status: false,
  app_logged_in_user: {},
  app_login_status: false,
  app_login_rights: {
    admin: true,
    production: true,
    reports: false,
    hierachy: true,
    maintenance: true,
    simulation: true,
    hierachy_level: 0,
  },
};

const getters = {
  getAppLoadingStatus: (state: any): boolean => state.app_loading_status,
  getAppLoggedInUser: (state: any): User => state.app_logged_in_user,
  getAppLoginStatus: (state: any): boolean => state.app_login_status,
  getAppLoginRights: (state: any): any => state.app_login_rights,
  
  /**
   * 
   * @param right the right that shall be checked 
   * @returns true or false
   */

  checkRights: (state: any) => (right: string): boolean => {
    if(right === 'admin') {return true}
    else if ( right === 'production') { if (state.app_login_rights.production === true) { return true} else {return false}}
    else if ( right === 'reports') { if (state.app_login_rights.reports === true) { return true} else {return false}}
    else if ( right === 'hierachy') { if (state.app_login_rights.hierachy === true) { return true} else {return false}}
    else if ( right === 'maintenance') { if (state.app_login_rights.maintenance === true) { return true} else {return false}}
    else if ( right === 'simulation') { if (state.app_login_rights.simulation === true) { return true} else {return false}}
    else {return false}
  }
};

const actions = {
  changeAppLoadingStatus({ commit }: any, new_app_loading_status: boolean) {
    commit("setAppLoadingStatus", new_app_loading_status);
  },
  logOut({ commit }: any) {
    commit("logOut");
  },
  logIn({ commit, dispatch, rootGetters }: any, user: User) {
    const rights = rootGetters.specificRole(user.role).rights;
    const user_index = rootGetters.allUsers.indexOf(user);
    const updated_lastlogin_user = user;
    updated_lastlogin_user.last_login = new Date;
    dispatch("editUser", {user: updated_lastlogin_user, user_index})
    commit("logIn", { user: updated_lastlogin_user, rights });
    router.push({ path: '/'})
  },
};

const mutations = {
  setAppLoadingStatus: (state: any, new_app_loading_status: boolean) =>
    (state.app_loading_status = new_app_loading_status),
  logOut: (state: any) => (
    (state.app_logged_in_user = {}),
    (state.app_login_status = false),
    (state.app_login_rights = {
      admin: false,
      production: false,
      reports: false,
      hierachy: false,
    })
  ),
  logIn: (state: any, { user, rights }: { user: User; rights: any }) => (
    (state.app_logged_in_user = user),
    (state.app_login_status = true),
    (state.app_login_rights = rights)
  ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
