import axios from "axios";
import { ObjectId } from "mongodb";
import { Assembly } from "../../../types/assembly";

const url = "api/simulation/assembly/";

const state = {
  sim_assemblys: [],
};

const getters = {
  /**
   * Title: allSimAssemblys()
   *
   * Description: Returns all SimAssemblys as an array
   */
  allSimAssemblys: (state: any): Assembly[] => state.sim_assemblys,

  /**
   * Title: specificSimAssembly()
   *
   * Description: Returns a specific SimAssembly
   *
   * @param {ObjectId} sim_assembly_id the id of the sim_assembly that should be returned
   *
   */
  specificSimAssembly:
    (state: any) =>
    (sim_assembly_id: ObjectId): Assembly => {
      return state.sim_assemblys.find(
        (sim_assembly: Assembly) => sim_assembly._id === sim_assembly_id
      );
    },
};

const actions = {
  /**
   * Title: fetchSimAssemblys()
   *
   * Route: GET /sim_assembly
   * Description: Fetches data for all SimAssemblys
   */

  async fetchSimAssemblys({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url + "/all");
      commit("setSimAssemblys", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Baugruppen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newSimAssembly()
   *
   * Route: Post /sim_assembly/
   * Description: Adds new SimAssembly to the Database
   *
   * @param {String} name the name of new sim_assembly
   * @param {String} description the description of the new sim_assembly
   * @param {String} location the location of the new sim_assembly
   * @param {ObjectId} type the type of the new sim_assembly
   *
   * Info: Every other attribute of a sim_assembly is beeing added by the API and returned as a response which then is pushed to the state
   */
  async newSimAssembly(
    { commit, dispatch }: any,
    {
      name,
      description,
      location,
    }: { name: string; description: string; location: string }
  ) {
    try {
      const response = await axios.post(url, {
        name,
        description,
        location,
        components: {
          machine_groups: [],
          sensors: [],
        },
      });
      commit("addSimAssembly", response.data);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Baugruppe gespeichert",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Baugruppe konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newSimAssembly()
   *
   * Route: Delete /sim_assembly/id
   * Description: Deletes a SimAssembly from the Database
   *
   * @param {SimAssembly} sim_assembly the sim_assembly that is being deleted
   *
   */
  async deleteSimAssembly({ commit, dispatch }: any, sim_assembly: Assembly) {
    try {
      // const delete_url = url + sim_assembly._id
      // const response = await axios.delete(delete_url);
      commit("removeSimAssembly", sim_assembly);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Baugruppe wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Baugruppe konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editSimAssembly()
   *
   * Route: Put /sim_assembly/id
   * Description: Pushes the new state of a sim_assembly to the databas
   *
   * @param {SimAssembly} sim_assembly the new state sim_assembly that is being edited
   * @param {Number} sim_assembly_index the index of the machin within the state array, transmitted as the new state of the sim_assembly would not allow to find the index here as new state is different from the old state
   *
   * Info: Every other attribute of a sim_assembly is beeing added by the API and returned as a response which then is pushed to the state
   */

  async editSimAssembly(
    { commit, dispatch }: any,
    { sim_assembly, sim_assembly_index }: { sim_assembly: Assembly; sim_assembly_index: number }
  ) {
    try {
      const put_url = url + sim_assembly._id;
      const response = await axios.put(put_url, sim_assembly);
      const data = response.data;
      commit("updateSimAssembly", { sim_assembly: data, sim_assembly_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: mqttSimAssemblyStatusUpdate()
   *
   * Description: Updates the Status of a sim_assembly received by mqtt
   *
   * @param {Sensor} sim_assembly_id the id of the sim_assembly
   * @param {Number} sim_assembly_status the status of the sim_assembly
   */
  mqttSimAssemblyStatusUpdate(
    { commit, getters }: any,
    {
      sim_assembly_id,
      sim_assembly_status,
    }: { sim_assembly_id: ObjectId; sim_assembly_status: number }
  ) {
    const sim_assembly_index = getters.allSimAssemblys.indexOf(
      getters.specificSimAssembly(sim_assembly_id)
    );
    commit("setSimAssemblyStatus", { sim_assembly_index, sim_assembly_status });
  },
};

const mutations = {
  setSimAssemblys: (state: any, sim_assemblys: Assembly[]) =>
    (state.sim_assemblys = sim_assemblys),
  addSimAssembly: (state: any, sim_assembly: Assembly) =>
    state.sim_assemblys.push(sim_assembly),
  removeSimAssembly: (state: any, sim_assembly: Assembly) =>
    state.sim_assemblys.splice(state.sim_assemblys.indexOf(sim_assembly), 1),
  updateSimAssembly: (state: any, payload: any) =>
    (state.sim_assemblys[payload.sim_assembly_index] = payload.sim_assembly),
  setSimAssemblyStatus: (state: any, payload: any) => (
    (state.sim_assemblys[payload.sim_assembly_index].status =
       payload.sim_assembly_status.valueOf())
  ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
