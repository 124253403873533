import store from "../vuex-store/index";

/**
 * Title: processSimulationMessage
 *
 * Description: processes MQTT Simulation Messages and calls according state management functions
 *
 * @param id The ID of the Simulation that is supposed to be changed
 * @param parameter The Paramter that is supposed to be changed
 * @param message Message of the MQTT-Message
 */
export function processSimulationMessage(
  id: string,
  parameter: string,
  message: Buffer
) {
  //console.log("[MQTT - Simulation] " + parameter + [" | "] + message);
  if (parameter === "status") {
    store.dispatch("mqttSimulationStatusUpdate", {
      simulation_id: id,
      simulation_status: Number.parseFloat(message.toString()),
    });

    if (Number.parseFloat(message.toString()) === 0) {
      const simulation_name =
        store.getters.specificSimulation(id).name;
      store.dispatch("newNotification", {
        text: 'Simulation ' + simulation_name + ' fertiggestellt!',
        type: "info",
        timeout: 3000,
        hierachy_level: 5,
        required_right: 'simulation',
      });
      store.dispatch("fetchSimulations")
    }
  }
}

export function processSimMachineGroupMessage(
  id: string,
  parameter: string,
  message: Buffer
) {
  console.log("[MQTT - Simulation - MachineGroup] " + parameter + [" | "] + message);
  if (parameter === "assigned") {
    let value;
    if (message.toString() === "true") {
      value = true;
    } else {
      value = false;
    }

    store.dispatch("mqttSimMachineGroupAssignedUpdate", {
      sim_machinegroup_id: id,
      assigned: value,
    });
  }
}

export function processSimMachineMessage(
  id: string,
  parameter: string,
  message: Buffer
) {
  console.log("[MQTT - Simulation - Machine] " + parameter + [" | "] + message);
  if (parameter === "assigned") {
    let value;
    if (message.toString() === "true") {
      value = true;
    } else {
      value = false;
    }

    store.dispatch("mqttSimMachineAssignedUpdate", {
      sim_machine_id: id,
      assigned: value,
    });
  }
}

export function processSimSensorMessage(
  id: string,
  parameter: string,
  message: Buffer
) {
  console.log("[MQTT - Simulation - Sensor] " + parameter + [" | "] + message);
  if (parameter === "assigned") {
    let value;
    if (message.toString() === "true") {
      value = true;
    } else {
      value = false;
    }

    store.dispatch("mqttSimSensorAssignedUpdate", {
      sim_sensor_id: id,
      assigned: value,
    });
  }
}