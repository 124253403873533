import axios from "axios";

const url = "api/admin/";

const actions = {
  /**
   * Title: startAllMachines()
   *
   * Description: trys to start a Machine
   *
   */
  async startAllMachines({ dispatch }: any) {
    try {
      const patch_url = url + "command/";
      const response = await axios.patch(patch_url, {
        command: "startMachines",
      });
      dispatch(
        "newNotification",
        {
          text: "Maschinen wurden gestartet.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Maschinen konnten nicht gestartet werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: stopAllMachines()
   *
   * Description: trys to stop all Machines
   *
   */
  async stopAllMachines({ dispatch }: any) {
    try {
      const patch_url = url + "command/";
      const response = await axios.patch(patch_url, {
        command: "stopMachines",
      });
      console.log(response);
      dispatch(
        "newNotification",
        {
          text: "Maschinen wurden gestoppt.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Maschinen konnten nicht gestoppt werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: startAllSensors()
   *
   * Description: trys to start all Sensors
   *

   */
  async startAllSensors({ dispatch }: any) {
    try {
      const patch_url = url + "command/";
      const response = await axios.patch(patch_url, {
        command: "startSensors",
      });
      dispatch(
        "newNotification",
        {
          text: "Sensoren wurden gestartet.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Sensoren konnten nicht gestartet werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: stopSensor()
   *
   * Description: trys to stop all Sensors
   *
   */
  async stopAllSensors({ dispatch }: any) {
    try {
      const patch_url = url + "command/";
      const response = await axios.patch(patch_url, { command: "stopSensors" });
      console.log(response);
      dispatch(
        "newNotification",
        {
          text: "Sensoren wurden gestoppt.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Sensoren konnten nicht gestoppt werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: resetDatabase()
   *
   * Description: trys to reset the Database
   *
   */
  async resetDatabase({ dispatch }: any) {
    try {
      console.log("Reset")
      dispatch("changeAppLoadingStatus", true);
      const put_url = url + "resetDatabase/";
      const response = await axios.put(put_url);
      console.log(response);
      dispatch(
        "newNotification",
        {
          text: "Datenbank wurde erfolgreich zurückgesetzt.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
      dispatch("changeAppLoadingStatus", false);
      location.reload();
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Datenbank konnte nicht zurückgesetzt werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },
};

export default {
  actions,
};
