import store from "../vuex-store/index";

/**
 * Title: processAssemblyMessage
 * 
 * Description: processes MQTT Assembly Messages and calls according state management functions
 * 
 * @param id The ID of the Assembly that is supposed to be changed
 * @param parameter The Paramter that is supposed to be changed
 * @param message Message of the MQTT-Message
 */
export function processAssemblyMessage(
  id: string,
  parameter: string,
  message: Buffer
) {
  //console.log("[MQTT - Assembly] " + parameter + [" | "] + message);
  if (parameter === "status") {
    store.dispatch("mqttAssemblyStatusUpdate", {
      assembly_id: id,
      assembly_status: Number.parseFloat(message.toString()),
    });
  } else if (parameter === "produced_by") {
    store.dispatch("mqttAssemblyProducedByUpdate", {
      assembly_id: id,
      produced_by: message.toString(),
    });
  } else if (parameter === "process_started") {
    store.dispatch("mqttAssemblyProcessStartedUpdate", {
      assembly_id: id,
      process_started: message.toString(),
    });
  } else if (parameter === "process_finished") {
    store.dispatch("mqttAssemblyProcessFinishedUpdate", {
      assembly_id: id,
      process_finished: message.toString(),
    });
  }
}
