import axios from "axios";
import { ObjectId } from "mongodb";
import { User } from "../../types/user";

const url = "api/user/";

const state = {
  users: [],
};

const getters = {
  /**
   * Title: allUsers()
   *
   * Description: Returns all Users as an array
   */
  allUsers: (state: any): User[] => state.users,

  /**
   * Title: specificUser()
   *
   * Description: Returns a specific User
   *
   * @param {ObjectId} user_id the id of the user that should be returned
   *
   */
  specificUser:
    (state: any) =>
    (user_id: ObjectId): User => {
      return state.users.find((user: User) => user._id === user_id);
    },
};

const actions = {
  /**
   * Title: fetchUsers()
   *
   * Route: GET /user
   * Description: Fetches data for all Users
   */

  async fetchUsers({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setUsers", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Nutzer konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newUser()
   *
   * Route: Post /user/
   * Description: Adds new User to the Database
   *
   * @param {String} name the name of new user
   * @param {String} description the description of the new user
   * @param {String} location the location of the new user
   * @param {ObjectId} type the type of the new user
   *
   * Info: Every other attribute of a user is beeing added by the API and returned as a response which then is pushed to the state
   */
  async newUser(
    { commit, dispatch }: any,
    {
      login,
      first_name,
      last_name,
      role,
      password
    }: { login: string; first_name: string; last_name: string; role: ObjectId, password: string }
  ) {
    try {
      const response = await axios.post(url, {
        login,
        first_name,
        last_name,
        role,
        password,
        created_by: "621d43fac49e49629ef7de9f",
      });
      commit("addUser", response.data);
      dispatch(
        "newNotification",
        {
          text: "Nutzer gespeichert",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Nutzer konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newUser()
   *
   * Route: Delete /user/id
   * Description: Deletes a User from the Database
   *
   * @param {User} user the user that is being deleted
   *
   */
  async deleteUser({ commit, dispatch }: any, user: User) {
    try {
      // const delete_url = url + user._id
      // const response = await axios.delete(delete_url);
      commit("removeUser", user);
      dispatch(
        "newNotification",
        {
          text: "Nutzer wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Nutzer konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editUser()
   *
   * Route: Put /user/id
   * Description: Pushes the new state of a user to the databas
   *
   * @param {User} user the new state user that is being edited
   * @param {Number} user_index the index of the machin within the state array, transmitted as the new state of the user would not allow to find the index here as new state is different from the old state
   *
   * Info: Every other attribute of a user is beeing added by the API and returned as a response which then is pushed to the state
   */

  async editUser(
    { commit, dispatch }: any,
    { user, user_index }: { user: User; user_index: number }
  ) {
    try {
      const put_url = url + user._id;
      const response = await axios.put(put_url, user);
      const data = response.data;
      commit("updateUser", { user: data, user_index });
      // dispatch(
      //   "newNotification",
      //   {
      //     text: "Änderungen wurden erfolgreich gespeichert.",
      //     type: "success",
      //     timeout: 3000,
      //   },
      //   { root: true }
      // );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },
};

const mutations = {
  setUsers: (state: any, users: User[]) => (state.users = users),
  addUser: (state: any, user: User) => state.users.push(user),
  removeUser: (state: any, user: User) =>
    state.users.splice(state.users.indexOf(user), 1),
  updateUser: (state: any, payload: any) =>
    (state.users[payload.user_index] = payload.user),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
