import axios from "axios";
import { ObjectId } from "mongodb";
import { MaintenanceType} from "../../types/maintenanceType";

const url = "api/maintenanceType/";

const state = {
  maintenance_types: [],
};

const getters = {
  /**
   * Title: allMaintenanceTypes()
   *
   * Description: Returns all MaintenanceTypess as an array
   */
  allMaintenanceTypes: (state: any):MaintenanceType[] => state.maintenance_types,

  /**
   * Title: specificMaintenanceType()
   *
   * Description: Returns a specific MaintenanceType
   * 
   * @param {ObjectId} maintenance_type_id the id of the maintenancetype that should be returned
   * 
   */
  specificMaintenanceType: (state: any) => (maintenance_type_id: ObjectId): MaintenanceType => {
    return state.maintenance_types.find(
      (maintenance_type: MaintenanceType) => maintenance_type._id === maintenance_type_id
    );
  },
};

const actions = {
  /**
   * Title: fetchMaintenanceTypes()
   *
   * Route: GET /maintenanceType/
   * Description: Fetches data for all MaintenanceTypes
   */

  async fetchMaintenanceTypes({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setMaintenanceTypes", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        'newNotification',
        {
          text: "Wartungstypen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        {root:true}
      );
    }
  },

  /**
   * Title: newMaintenanceType()
   *
   * Route: Post /maintenanceType/
   * Description: Adds a new maintenancetype to the Database
   *
   * @param {String} name the name of new maintenancetype
   * @param {String} description the description of the new maintenancetype
   * @param {String} icon the icon of the new maintenancetype
   *
   * Info: Every other attribute of a maintenance is beeing added by the API and returned as a response which then is pushed to the state
   */

  async newMaintenanceType(
    { commit, dispatch }: any,
    { name, description, icon }: { name: string, description: string, icon: string }
  ) {

    try {
      const response = await axios.post(url, {
      name,
      description,
      icon,
      });
      commit('addMaintenanceType', response.data);

      dispatch(
        'newNotification',
        {
          text: "Wartungstyp gespeichert.",
          type: "success",
          timeout: 3000,
        },
        {root:true}
      );
    } catch (error) {
      console.log(error);
      dispatch(
        'newNotification',
        {
          text: "Wartungstyp konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        {root:true}
      );
    }
  },

  /**
   * Title: deleteMaintenanceType()
   *
   * Route: Delete /maintenanceType/id
   * Description: Deletes maintenancetype from the Database
   *
   * @param {MaintenanceType} maintenance_type the name of new maintenancetype
   *
   */
  async deleteMaintenanceType({ commit, dispatch }: any, maintenance_type: MaintenanceType) {
    try {
      //const delete_url = url + maintenance._id
      //const response = await axios.delete(delete_url);
      commit("removeMaintenanceType", maintenance_type);
      dispatch(
        'newNotification',
        {
          text: "Wartungstyp wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        {root:true}
      );
    } catch (error) {
      console.log(error);
      dispatch(
        'newNotification',
        {
          text: "Wartungstyp konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        {root:true}
      );
    }
  },

  /**
   * Title: editMaintenanceType()
   *
   * Route: Post /maintenanceType/id
   * Description: Pushes the new state of a maintenance to the databas
   *
   * @param {Maintenance} maintenance_type the new state of the maintenancetype that is being edited
   * @param {Number} maintenance_type_index the index of the machin within the state array, transmitted as the new state of the maintenancetype would not allow to find the index here as new state is different from the old state
   *
   */
  async editMaintenanceType({ commit, dispatch }: any, { maintenance_type, maintenance_type_index }: any) {
    try {
      console.log(maintenance_type)
      const put_url = url + maintenance_type._id
      const response = await axios.put(put_url, maintenance_type);
      const data = response.data
      commit("updateMaintenanceType", { maintenance_type: data, maintenance_type_index });
      //commit("updateMaintenanceType", { maintenance_type, maintenance_type_index });
      dispatch(
        'newNotification',
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        {root:true}
      );
    } catch (error) {
      console.log(error);
      dispatch(
        'newNotification',
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        {root:true}
      );
    }
  },
};

const mutations = {
  setMaintenanceTypes: (state: any, maintenance_types: MaintenanceType[]) => (state.maintenance_types = maintenance_types),
  addMaintenanceType: (state: any, maintenance_type: MaintenanceType) => state.maintenance_types.push(maintenance_type),
  removeMaintenanceType: (state: any, maintenance_type: MaintenanceType) =>
    state.maintenance_types.splice(state.maintenance_types.indexOf(maintenance_type), 1),
  updateMaintenanceType: (state: any, payload: any) =>
    (state.maintenance_types[payload.maintenance_type_index] = payload.maintenance_type),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
