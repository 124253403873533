import axios from "axios";
import { ObjectId } from "mongodb";
import { SensorType } from "../../types/sensorType";

const url = "api/sensorType/";

const state = {
  sensor_types: [],
};

const getters = {
  /**
   * Title: allSensorTypes()
   *
   * Description: Returns all SensorTypess as an array
   */
  allSensorTypes: (state: any): SensorType[] => state.sensor_types,

  /**
   * Title: specificSensorType()
   *
   * Description: Returns a specific SensorType
   *
   * @param {ObjectId} sensor_type_id the id of the sensortype that should be returned
   *
   */
  specificSensorType:
    (state: any) =>
    (sensor_type_id: ObjectId): SensorType => {
      return state.sensor_types.find(
        (sensor_type: SensorType) => sensor_type._id === sensor_type_id
      );
    },
};

const actions = {
  /**
   * Title: fetchSensorTypes()
   *
   * Route: GET /sensorType/
   * Description: Fetches data for all SensorTypes
   */

  async fetchSensorTypes({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setSensorTypes", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Sensortypen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newSensorType()
   *
   * Route: Post /sensorType/
   * Description: Adds a new sensortype to the Database
   *
   * @param {String} name the name of new sensortype
   * @param {String} description the description of the new sensortype
   * @param {String} icon the icon of the new sensortype
   *
   * Info: Every other attribute of a sensor is beeing added by the API and returned as a response which then is pushed to the state
   */

  async newSensorType(
    { commit, dispatch }: any,
    {
      name,
      description,
      icon,
      unit,
    }: {
      name: string;
      description: string;
      icon: string,
      unit: string,
    }
  ) {
    try {
      const response = await axios.post(url, {
        name,
        description,
        icon,
        unit,
        maintenance_plan: [],
      });
      commit("addSensorType", response.data);

      dispatch(
        "newNotification",
        {
          text: "Sensortyp gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Sensortyp konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: deleteSensorType()
   *
   * Route: Delete /sensorType/id
   * Description: Deletes sensortype from the Database
   *
   * @param {SensorType} sensor_type the name of new sensortype
   *
   */
  async deleteSensorType(
    { commit, dispatch }: any,
    sensor_type: SensorType
  ) {
    try {
      //const delete_url = url + sensor._id
      //const response = await axios.delete(delete_url);
      commit("removeSensorType", sensor_type);
      dispatch(
        "newNotification",
        {
          text: "Sensortyp wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Sensortyp konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editSensorType()
   *
   * Route: Post /sensorType/id
   * Description: Pushes the new state of a sensor to the databas
   *
   * @param {Sensor} sensor_type the new state of the sensortype that is being edited
   * @param {Number} sensor_type_index the index of the machin within the state array, transmitted as the new state of the sensortype would not allow to find the index here as new state is different from the old state
   *
   */
  async editSensorType(
    { commit, dispatch }: any,
    { sensor_type, sensor_type_index }: any
  ) {
    try {
      const put_url = url + sensor_type._id;
      const response = await axios.put(put_url, sensor_type);
      const data = response.data;
      console.log(data);
      commit("updateSensorType", {
        sensor_type: data,
        sensor_type_index,
      });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },
};

const mutations = {
  setSensorTypes: (state: any, sensor_types: SensorType[]) =>
    (state.sensor_types = sensor_types),
  addSensorType: (state: any, sensor_type: SensorType) =>
    state.sensor_types.push(sensor_type),
  removeSensorType: (state: any, sensor_type: SensorType) =>
    state.sensor_types.splice(state.sensor_types.indexOf(sensor_type), 1),
  updateSensorType: (state: any, payload: any) =>
    (state.sensor_types[payload.sensor_type_index] = payload.sensor_type),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
