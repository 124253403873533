import axios from "axios";
import { ObjectId } from "mongodb";
import { AssemblyType } from "../../types/assemblyType";

const url = "api/assemblyType/";

const state = {
  assembly_types: [],
};

const getters = {
  /**
   * Title: allAssemblyTypes()
   *
   * Description: Returns all AssemblyTypess as an array
   */
  allAssemblyTypes: (state: any): AssemblyType[] => state.assembly_types,

  /**
   * Title: specificAssemblyType()
   *
   * Description: Returns a specific AssemblyType
   *
   * @param {ObjectId} assembly_type_id the id of the assemblytype that should be returned
   *
   */
  specificAssemblyType:
    (state: any) =>
    (assembly_type_id: ObjectId): AssemblyType => {
      return state.assembly_types.find(
        (assembly_type: AssemblyType) => assembly_type._id === assembly_type_id
      );
    },
};

const actions = {
  /**
   * Title: fetchAssemblyTypes()
   *
   * Route: GET /assemblyType/
   * Description: Fetches data for all AssemblyTypes
   */

  async fetchAssemblyTypes({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setAssemblyTypes", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Baugruppentypen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newAssemblyType()
   *
   * Route: Post /assemblyType/
   * Description: Adds a new assemblytype to the Database
   *
   * @param {String} name the name of new assemblytype
   * @param {String} description the description of the new assemblytype
   * @param {String} icon the icon of the new assemblytype
   *
   * Info: Every other attribute of a assembly is beeing added by the API and returned as a response which then is pushed to the state
   */

  async newAssemblyType(
    { commit, dispatch }: any,
    {
      name,
      description,
      average_duration,
      machine_type,
    }: {
      name: string;
      description: string;
      average_duration: number;
      machine_type: ObjectId;
    }
  ) {
    try {
      const response = await axios.post(url, {
        name,
        description,
        average_duration,
        machine_type,
        subassembly_types: [],
        material_types: [],
      });
      commit("addAssemblyType", response.data);

      dispatch(
        "newNotification",
        {
          text: "Baugruppentyp gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Baugruppentyp konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: deleteAssemblyType()
   *
   * Route: Delete /assemblyType/id
   * Description: Deletes assemblytype from the Database
   *
   * @param {AssemblyType} assembly_type the name of new assemblytype
   *
   */
  async deleteAssemblyType(
    { commit, dispatch }: any,
    assembly_type: AssemblyType
  ) {
    try {
      //const delete_url = url + assembly._id
      //const response = await axios.delete(delete_url);
      commit("removeAssemblyType", assembly_type);
      dispatch(
        "newNotification",
        {
          text: "Baugruppentyp wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Baugruppentyp konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editAssemblyType()
   *
   * Route: Post /assemblyType/id
   * Description: Pushes the new state of a assembly to the databas
   *
   * @param {Assembly} assembly_type the new state of the assemblytype that is being edited
   * @param {Number} assembly_type_index the index of the machin within the state array, transmitted as the new state of the assemblytype would not allow to find the index here as new state is different from the old state
   *
   */
  async editAssemblyType(
    { commit, dispatch }: any,
    { assembly_type, assembly_type_index }: any
  ) {
    try {
      const put_url = url + assembly_type._id;
      const response = await axios.put(put_url, assembly_type);
      const data = response.data;
      commit("updateAssemblyType", {
        assembly_type: data,
        assembly_type_index,
      });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },
};

const mutations = {
  setAssemblyTypes: (state: any, assembly_types: AssemblyType[]) =>
    (state.assembly_types = assembly_types),
  addAssemblyType: (state: any, assembly_type: AssemblyType) =>
    state.assembly_types.push(assembly_type),
  removeAssemblyType: (state: any, assembly_type: AssemblyType) =>
    state.assembly_types.splice(state.assembly_types.indexOf(assembly_type), 1),
  updateAssemblyType: (state: any, payload: any) =>
    (state.assembly_types[payload.assembly_type_index] = payload.assembly_type),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
