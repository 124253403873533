import store from "../vuex-store/index";

/**
 * Title: processMachineGroupMessage
 *
 * Description: processes MQTT Sensor Messages and calls according state management functions
 *
 * @param id The ID of the MachineGroup that is supposed to be changed
 * @param parameter The Paramter that is supposed to be changed
 * @param message Message of the MQTT-Message
 */
export function processMachineGroupMessage(
  id: string,
  parameter: string,
  message: Buffer
) {
  //console.log("[MQTT - MachineGroup] " + parameter + " | " + message);
  if (parameter === "status") {
    store.dispatch("mqttMachineGroupStatusUpdate", {
      machinegroup_id: id,
      current_status: Number.parseFloat(message.toString()),
    });
  } else if (parameter === "assigned") {
    let value;
    if (message.toString() === "true") {
      value = true;
    } else {
      value = false;
    }

    store.dispatch("mqttMachineGroupAssignedUpdate", {
      machinegroup_id: id,
      assigned: value,
    });
  } else if (parameter === "current_throughput") {
    store.dispatch("mqttMachineGroupCurrentThroughputUpdate", {
      machinegroup_id: id,
      current_throughput: Number.parseFloat(message.toString()),
    });
  } else if (parameter === "average_processing_time") {
    store.dispatch("mqttMachineGroupAverageProcessingTimeUpdate", {
      machinegroup_id: id,
      average_processing_time: Number.parseFloat(message.toString()),
    });
  }
}
