import axios from "axios";
import { ObjectId } from "mongodb";
import { MachineGroup } from "../../../types/machinegroup";

const url = "api/simulation/machineGroup/";

const state = {
  sim_machinegroups: [],
};

const getters = {
  /**
   * Title: allSimMachineGroups()
   *
   * Description: Returns all SimMachineGroups as an array
   */
  allSimMachineGroups: (state: any): MachineGroup[] => state.sim_machinegroups,

  /**
   * Title: specificSimMachineGroup()
   *
   * Description: Returns a specific SimMachineGroup
   *
   * @param {ObjectId} sim_machinegroup_id the id of the sim_machinegroup that should be returned
   *
   */
  specificSimMachineGroup:
    (state: any) =>
    (sim_machinegroup_id: ObjectId): MachineGroup => {
      return state.sim_machinegroups.find(
        (sim_machinegroup: MachineGroup) =>
          sim_machinegroup._id === sim_machinegroup_id
      );
    },
};

const actions = {
  /**
   * Title: fetchSimMachineGroups()
   *
   * Route: GET /sim_machinegroup
   * Description: Fetches data for all SimMachineGroups
   */

  async fetchSimMachineGroups({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url + "/all");
      commit("setSimMachineGroups", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Maschinengruppen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newSimMachineGroup()
   *
   * Route: Post /sim_machinegroup/
   * Description: Adds new SimMachineGroup to the Database
   *
   * @param {String} name the name of new sim_machinegroup
   * @param {String} description the description of the new sim_machinegroup
   * @param {String} location the location of the new sim_machinegroup
   * @param {ObjectId} type the type of the new sim_machinegroup
   *
   * Info: Every other attribute of a sim_machinegroup is beeing added by the API and returned as a response which then is pushed to the state
   */
  async newSimMachineGroup(
    { commit, dispatch }: any,
    {
      name,
      description,
      location,
    }: { name: string; description: string; location: string }
  ) {
    try {
      const response = await axios.post(url, {
        name,
        description,
        location,
        components: {
          machines: [],
          sensors: [],
        },
      });
      commit("addSimMachineGroup", response.data);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Maschinengruppe gespeichert",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Maschinengruppe konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newSimMachineGroup()
   *
   * Route: Delete /sim_machinegroup/id
   * Description: Deletes a SimMachineGroup from the Database
   *
   * @param {SimMachineGroup} sim_machinegroup the sim_machinegroup that is being deleted
   *
   */
  async deleteSimMachineGroup(
    { commit, dispatch }: any,
    sim_machinegroup: MachineGroup
  ) {
    try {
      const delete_url = url + sim_machinegroup._id;
      const response = await axios.delete(delete_url);
      commit("removeSimMachineGroup", sim_machinegroup);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Maschinengruppe wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Maschinengruppe konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editSimMachineGroup()
   *
   * Route: Put /sim_machinegroup/id
   * Description: Pushes the new state of a sim_machinegroup to the databas
   *
   * @param {SimMachineGroup} sim_machinegroup the new state sim_machinegroup that is being edited
   * @param {Number} sim_machinegroup_index the index of the machin within the state array, transmitted as the new state of the sim_machinegroup would not allow to find the index here as new state is different from the old state
   *
   * Info: Every other attribute of a sim_machinegroup is beeing added by the API and returned as a response which then is pushed to the state
   */

  async editSimMachineGroup(
    { commit, dispatch }: any,
    {
      sim_machinegroup,
      sim_machinegroup_index,
    }: { sim_machinegroup: MachineGroup; sim_machinegroup_index: number }
  ) {
    try {
      const put_url = url + sim_machinegroup._id;
      const response = await axios.put(put_url, sim_machinegroup);
      const data = response.data;
      commit("updateSimMachineGroup", {
        sim_machinegroup: data,
        sim_machinegroup_index,
      });
      dispatch(
        "newNotification",
        {
          text: "Simulierte Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: addSimMachineGroupToFactory()
   *
   * Route: Put /factory/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} sim_machinegroup_id the id sim_machinegroup that is being edited
   * @param {Number} sim_machine_id the id of the sim_machine that is beeing added
   *
   */

  async addSimMachineToSimMachineGroup(
    { commit, dispatch, getters }: any,
    {
      sim_machinegroup_id,
      sim_machine_id,
    }: { sim_machinegroup_id: ObjectId; sim_machine_id: ObjectId }
  ) {
    try {
      const patch_url = url + sim_machinegroup_id + "/addMachine";
      const sim_machinegroup_index = getters.allSimMachineGroups.indexOf(
        getters.specificSimMachineGroup(sim_machinegroup_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sim_machine_id,
      });
      const data = response.data;
      commit("updateSimMachineGroup", {
        sim_machinegroup: data,
        sim_machinegroup_index,
      });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: removeSimMachineGroupToFactory()
   *
   * Route: Put /factory/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} factory_id the id factory that is being edited
   * @param {Number} sim_machinegroup_id the id of the sim_machinegroup that is beeing added
   *
   */

  async removeSimMachineFromSimMachineGroup(
    { commit, dispatch, getters }: any,
    {
      sim_machinegroup_id,
      sim_machine_id,
    }: { sim_machine_id: ObjectId; sim_machinegroup_id: ObjectId }
  ) {
    try {
      const patch_url = url + sim_machinegroup_id + "/removeMachine";
      const sim_machinegroup_index = getters.allSimMachineGroups.indexOf(
        getters.specificSimMachineGroup(sim_machinegroup_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sim_machine_id,
      });
      const data = response.data;
      commit("updateSimMachineGroup", {
        sim_machinegroup: data,
        sim_machinegroup_index,
      });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: addSimSensorGroupToFactory()
   *
   * Route: Put /factory/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} factory_id the id factory that is being edited
   * @param {Number} sim_sensor_id the id of the sim_machinegroup that is beeing added
   *
   */

  async addSimSensorToSimMachineGroup(
    { commit, dispatch, getters }: any,
    {
      sim_machinegroup_id,
      sim_sensor_id,
    }: { sim_machinegroup_id: ObjectId; sim_sensor_id: ObjectId }
  ) {
    try {
      const patch_url = url + sim_machinegroup_id + "/addSensor";
      const sim_machinegroup_index = getters.allSimMachineGroups.indexOf(
        getters.specificSimMachineGroup(sim_machinegroup_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sim_sensor_id,
      });
      const data = response.data;
      commit("updateSimMachineGroup", {
        sim_machinegroup: data,
        sim_machinegroup_index,
      });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: addSimSensorGroupToFactory()
   *
   * Route: Put /factory/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} factory_id the id factory that is being edited
   * @param {Number} sim_sensor_id the id of the sim_machinegroup that is beeing added
   *
   */

  async removeSimSensorFromSimMachineGroup(
    { commit, dispatch, getters }: any,
    {
      sim_machinegroup_id,
      sim_sensor_id,
    }: { sim_machinegroup_id: ObjectId; sim_sensor_id: ObjectId }
  ) {
    try {
      const patch_url = url + sim_machinegroup_id + "/removeSensor";
      const sim_machinegroup_index = getters.allSimMachineGroups.indexOf(
        getters.specificSimMachineGroup(sim_machinegroup_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sim_sensor_id,
      });
      const data = response.data;
      commit("updateSimMachineGroup", {
        sim_machinegroup: data,
        sim_machinegroup_index,
      });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  mqttSimMachineGroupStatusUpdate(
    { commit, getters }: any,
    {
      sim_machinegroup_id,
      current_status,
    }: { sim_machinegroup_id: ObjectId; current_status: number }
  ) {
    const sim_machinegroup_index = getters.allSimMachineGroups.indexOf(
      getters.specificSimMachineGroup(sim_machinegroup_id)
    );
    commit("setSimMachineGroupStatus", {
      sim_machinegroup_index,
      current_status,
    });
  },

  /**
   * Title: mqttSensorValueUpdate()
   *
   * Description: Updates the Value of a sensor received by mqtt
   *
   * @param {Sensor} sensor_id the id of the sensor
   * @param {Number} assigned the value of the sensor
   */
  mqttSimMachineGroupAssignedUpdate(
    { commit, getters }: any,
    {
      sim_machinegroup_id,
      assigned,
    }: { sim_machinegroup_id: ObjectId; assigned: boolean }
  ) {
    const sim_machinegroup_index = getters.allSimMachineGroups.indexOf(
      getters.specificSimMachineGroup(sim_machinegroup_id)
    );
    commit("updateSimMachineGroupAssigned", {
      sim_machinegroup_index,
      assigned,
    });
  },
};

const mutations = {
  setSimMachineGroups: (state: any, sim_machinegroups: MachineGroup[]) =>
    (state.sim_machinegroups = sim_machinegroups),
  addSimMachineGroup: (state: any, sim_machinegroup: MachineGroup) =>
    state.sim_machinegroups.push(sim_machinegroup),
  removeSimMachineGroup: (state: any, sim_machinegroup: MachineGroup) =>
    state.sim_machinegroups.splice(
      state.sim_machinegroups.indexOf(sim_machinegroup),
      1
    ),
  updateSimMachineGroup: (state: any, payload: any) =>
    (state.sim_machinegroups[payload.sim_machinegroup_index] =
      payload.sim_machinegroup),
  setSimMachineGroupStatus: (state: any, payload: any) =>
    (state.sim_machinegroups[payload.sim_machinegroup_index].current_status =
      payload.current_status.valueOf()),
  updateSimMachineGroupAssigned: (state: any, payload: any) =>
    (state.sim_machinegroups[payload.sim_machinegroup_index].assigned =
      payload.assigned.valueOf()),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
