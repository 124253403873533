import store from "../vuex-store/index";

/**
 * Title: processSensorMessage
 *
 * Description: processes MQTT Sensor Messages and calls according state management functions
 *
 * @param id The ID of the Sensor that is supposed to be changed
 * @param parameter The Paramter that is supposed to be changed
 * @param message Message of the MQTT-Message
 */
export function processSensorMessage(
  id: string,
  parameter: string,
  message: Buffer
) {
  //console.log("[MQTT - Sensor] " + parameter + [" | "] + message);
  if (parameter === "status") {
    store.dispatch("mqttSensorStatusUpdate", {
      sensor_id: id,
      sensor_status: Number.parseFloat(message.toString()),
    });
  } else if (parameter === "current_reading") {
    store.dispatch("mqttSensorValueUpdate", {
      sensor_id: id,
      sensor_value: Number.parseFloat(message.toString()),
    });
  } else if (parameter === "operating_time") {
    store.dispatch("mqttSensorOperatingTimeUpdate", {
      sensor_id: id,
      operating_time: Number.parseFloat(message.toString()),
    });
  } else if (parameter === "error") {
    const error_code = Number.parseFloat(message.toString());
    if (error_code === 1) {
      store.dispatch("newNotification", {
        text:
          "[Sensor] - " +
          store.getters.specificSensor(id).name +
          " misst Werte außerhalb seines optimalen Betriebsbereichs!",
        type: "warning",
        timeout: 3000,
        hierachy_level: 4,
      });
    } else if (error_code === 2) {
      store.dispatch("newNotification", {
        text:
          "[Sensor] - " +
          store.getters.specificSensor(id).name +
          " misst Werte im kritischen Betriebsbereichs!",
        type: "error",
        timeout: 3000,
        hierachy_level: 4,
      });
    }
  } else if (parameter === "assigned") {
    let value;
    if (message.toString() === "true") {
      value = true;
    } else {
      value = false;
    }

    store.dispatch("mqttSensorAssignedUpdate", {
      sensor_id: id,
      assigned: value,
    });
  }
}
