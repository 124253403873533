import axios from "axios";
import { ObjectId } from "mongodb";
import { Assembly } from "../../types/assembly";

const url = "api/assembly/";

const state = {
  assemblys: [],
};

const getters = {
  /**
   * Title: allAssemblys()
   *
   * Description: Returns all Assemblys as an array
   */
  allAssemblys: (state: any): Assembly[] => state.assemblys,

  /**
   * Title: specificAssembly()
   *
   * Description: Returns a specific Assembly
   *
   * @param {ObjectId} assembly_id the id of the assembly that should be returned
   *
   */
  specificAssembly:
    (state: any) =>
    (assembly_id: ObjectId): Assembly => {
      return state.assemblys.find(
        (assembly: Assembly) => assembly._id === assembly_id
      );
    },
};

const actions = {
  /**
   * Title: fetchAssemblys()
   *
   * Route: GET /assembly
   * Description: Fetches data for all Assemblys
   */

  async fetchAssemblys({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setAssemblys", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Baugruppen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newAssembly()
   *
   * Route: Post /assembly/
   * Description: Adds new Assembly to the Database
   *
   * @param {String} name the name of new assembly
   * @param {String} description the description of the new assembly
   * @param {String} location the location of the new assembly
   * @param {ObjectId} type the type of the new assembly
   *
   * Info: Every other attribute of a assembly is beeing added by the API and returned as a response which then is pushed to the state
   */
  async newAssembly(
    { commit, dispatch }: any,
    {
      name,
      description,
      location,
    }: { name: string; description: string; location: string }
  ) {
    try {
      const response = await axios.post(url, {
        name,
        description,
        location,
        components: {
          machine_groups: [],
          sensors: [],
        },
      });
      commit("addAssembly", response.data);
      dispatch(
        "newNotification",
        {
          text: "Baugruppe gespeichert",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Baugruppe konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newAssembly()
   *
   * Route: Delete /assembly/id
   * Description: Deletes a Assembly from the Database
   *
   * @param {Assembly} assembly the assembly that is being deleted
   *
   */
  async deleteAssembly({ commit, dispatch }: any, assembly: Assembly) {
    try {
      // const delete_url = url + assembly._id
      // const response = await axios.delete(delete_url);
      commit("removeAssembly", assembly);
      dispatch(
        "newNotification",
        {
          text: "Baugruppe wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Baugruppe konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editAssembly()
   *
   * Route: Put /assembly/id
   * Description: Pushes the new state of a assembly to the databas
   *
   * @param {Assembly} assembly the new state assembly that is being edited
   * @param {Number} assembly_index the index of the machin within the state array, transmitted as the new state of the assembly would not allow to find the index here as new state is different from the old state
   *
   * Info: Every other attribute of a assembly is beeing added by the API and returned as a response which then is pushed to the state
   */

  async editAssembly(
    { commit, dispatch }: any,
    { assembly, assembly_index }: { assembly: Assembly; assembly_index: number }
  ) {
    try {
      const put_url = url + assembly._id;
      const response = await axios.put(put_url, assembly);
      const data = response.data;
      commit("updateAssembly", { assembly: data, assembly_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: mqttAssemblyStatusUpdate()
   *
   * Description: Updates the Status of a assembly received by mqtt
   *
   * @param {Sensor} assembly_id the id of the assembly
   * @param {Number} assembly_status the status of the assembly
   */
  mqttAssemblyStatusUpdate(
    { commit, getters }: any,
    {
      assembly_id,
      assembly_status,
    }: { assembly_id: ObjectId; assembly_status: number }
  ) {
    const assembly_index = getters.allAssemblys.indexOf(
      getters.specificAssembly(assembly_id)
    );
    commit("setAssemblyStatus", { assembly_index, assembly_status });
  },

  /**
   * Title: mqttAssemblyProducedByUpdate()
   *
   * Description: Updates the Status of a assembly received by mqtt
   *
   * @param {Sensor} assembly_id the id of the assembly
   * @param {Number} assembly_status the status of the assembly
   */
   mqttAssemblyProducedByUpdate(
    { commit, getters }: any,
    {
      assembly_id,
      produced_by,
    }: { assembly_id: ObjectId; produced_by: string }
  ) {
    const assembly_index = getters.allAssemblys.indexOf(
      getters.specificAssembly(assembly_id)
    );
    commit("setAssemblyProducedBy", { assembly_index, produced_by });
  },

  /**
   * Title: mqttAssemblyProducedByUpdate()
   *
   * Description: Updates the Status of a assembly received by mqtt
   *
   * @param {Sensor} assembly_id the id of the assembly
   * @param {Date} process_started the status of the assembly
   */
   mqttAssemblyProcessStartedUpdate(
    { commit, getters }: any,
    {
      assembly_id,
      process_started,
    }: { assembly_id: ObjectId; process_started: string }
  ) {
    const assembly_index = getters.allAssemblys.indexOf(
      getters.specificAssembly(assembly_id)
    );
    commit("setAssemblyProcessStarted", { assembly_index, process_started });
  },

  /**
   * Title: mqttAssemblyProducedByUpdate()
   *
   * Description: Updates the Status of a assembly received by mqtt
   *
   * @param {Sensor} assembly_id the id of the assembly
   * @param {Date} process_finished the status of the assembly
   */
   mqttAssemblyProcessFinishedUpdate(
    { commit, getters }: any,
    {
      assembly_id,
      process_finished,
    }: { assembly_id: ObjectId; process_finished: string }
  ) {
    const assembly_index = getters.allAssemblys.indexOf(
      getters.specificAssembly(assembly_id)
    );
    commit("setAssemblyProcessFinished", { assembly_index, process_finished });
  },
};

const mutations = {
  setAssemblys: (state: any, assemblys: Assembly[]) =>
    (state.assemblys = assemblys),
  addAssembly: (state: any, assembly: Assembly) =>
    state.assemblys.push(assembly),
  removeAssembly: (state: any, assembly: Assembly) =>
    state.assemblys.splice(state.assemblys.indexOf(assembly), 1),
  updateAssembly: (state: any, payload: any) =>
    (state.assemblys[payload.assembly_index] = payload.assembly),
  setAssemblyStatus: (state: any, payload: any) => (
    (state.assemblys[payload.assembly_index].status =
       payload.assembly_status.valueOf())
  ),
  setAssemblyProducedBy: (state: any, payload: any) => (
    (state.assemblys[payload.assembly_index].produced_by =
       payload.produced_by.valueOf())
  ),
  setAssemblyProcessStarted: (state: any, payload: any) => (
    (state.assemblys[payload.assembly_index].process_started =
       payload.process_started.valueOf())
  ),
  setAssemblyProcessFinished: (state: any, payload: any) => (
    (state.assemblys[payload.assembly_index].process_finished =
       payload.process_finished.valueOf())
  ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
