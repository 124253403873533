import store from "../vuex-store/index";

/**
 * Title: processMaintenanceMessage
 *
 * Description: processes MQTT Maintenance Messages and calls according state management functions
 *
 * @param id The ID of the Maintenance that is supposed to be changed
 * @param parameter The Paramter that is supposed to be changed
 * @param message Message of the MQTT-Message
 */
export function processMaintenanceMessage(
  id: string,
  parameter: string,
  message: Buffer
) {
  //console.log("[MQTT - Maintenance] " + parameter + " | " + message);
  if (parameter === "time_started") {
    store.dispatch("mqttMaintenanceTimeStartedUpdate", {
      maintenance_id: id,
      time_started: new Date(message.toString()),
    });
  } else if (parameter === "time_finished") {
    store.dispatch("mqttMaintenanceTimeFinishedUpdate", {
      maintenance_id: id,
      time_finished: new Date(message.toString()),
    });
  } else if (parameter === "status") {
    store.dispatch("mqttMaintenanceStatusUpdate", {
      maintenance_id: id,
      maintenance_status: Number.parseInt(message.toString()),
    });
  }
}
