import store from "../vuex-store/index";

/**
 * Title: processMachineMessage
 *
 * Description: processes MQTT Machine Messages and calls according state management functions
 *
 * @param id The ID of the Machine that is supposed to be changed
 * @param parameter The Paramter that is supposed to be changed
 * @param message Message of the MQTT-Message
 */
export function processMachineMessage(
  id: string,
  parameter: string,
  message: Buffer
) {
  //console.log("[MQTT - Machine] " + parameter + " | " + message);
  if (parameter === "status") {
    if (message.toString() === "null") {
      store.dispatch("mqttMachineStatusUpdate", {
        machine_id: id,
        machine_status: null,
      });
    } else {
      store.dispatch("mqttMachineStatusUpdate", {
        machine_id: id,
        machine_status: Number.parseFloat(message.toString()),
      });
    }
  } else if (parameter === "currently_working_on") {
    store.dispatch("mqttMachineCurrentlyWorkingOnUpdate", {
      machine_id: id,
      currently_working_on: message.toString(),
    });
  } else if (parameter === "operating_time") {
    store.dispatch("mqttMachineOperatingTimeUpdate", {
      machine_id: id,
      operating_time: Number.parseFloat(message.toString()),
    });
  } else if (parameter === "error") {
    const error_code = Number.parseFloat(message.toString());
    if (error_code === 1){
      store.dispatch("newNotification", {
        text: '[Maschine] - ' + store.getters.specificMachine(id).name + ' hat eine Störung!',
        type: "warning",
        timeout: 3000,
        hierachy_level: 4,
      });
    } else if (error_code === 2){
      store.dispatch("newNotification", {
        text: '[Maschine] - ' + store.getters.specificMachine(id).name + ' hat eine kritische Störung!',
        type: "error",
        timeout: 3000,
        hierachy_level: 4,
      });
    }
  } else if (parameter === "assigned") {
    let value;
    if (message.toString() === "true") {
      value = true;
    } else {
      value = false;
    }

    store.dispatch("mqttMachineAssignedUpdate", {
      machine_id: id,
      assigned: value,
    });
  } else if (parameter === "current_throughput") {
    store.dispatch("mqttMachineCurrentThroughputUpdate", {
      machine_id: id,
      current_throughput: Number.parseFloat(message.toString()),
    });
  } else if (parameter === "average_processing_time") {
    store.dispatch("mqttMachineAverageProcessingTimeUpdate", {
      machine_id: id,
      average_processing_time: Number.parseFloat(message.toString()),
    });
  }
}
