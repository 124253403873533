import { ObjectId } from "mongodb";
import { activeElementType } from "@/types/overview";

const state = {
  active_element_id: "",
  active_element_type: 0,
  navigation_drawer_title: "Digitaler Zwilling",
};

const getters = {
  overviewActiveElementId: (state: any): ObjectId => state.active_element_id,
  overviewActiveElementType: (state: any): activeElementType =>
    state.active_element_type,
  overviewNavigationDrawerTitle: (state: any): string =>
    state.navigation_drawer_title,
};

const actions = {
  updateOverviewActiveElement(
    { commit, rootGetters }: any,
    {
      new_active_element_id,
      new_active_element_type,
    }: {
      new_active_element_id: string;
      new_active_element_type: activeElementType;
    }
  ) {
    let new_navigation_drawer_title;
    switch (new_active_element_type) {
      case 0:
        new_navigation_drawer_title = "Digitaler Zwilling";
        commit("setOverviewActiveElement", {
          new_active_element_id,
          new_active_element_type,
          new_navigation_drawer_title,
        });
        break;

      case 1:
        new_navigation_drawer_title = rootGetters.specificFactory(
          new_active_element_id
        ).name;
        commit("setOverviewActiveElement", {
          new_active_element_id,
          new_active_element_type,
          new_navigation_drawer_title,
        });
        break;

      case 2:
        new_navigation_drawer_title = rootGetters.specificMachineGroup(
          new_active_element_id
        ).name;
        commit("setOverviewActiveElement", {
          new_active_element_id,
          new_active_element_type,
          new_navigation_drawer_title,
        });
        break;

      case 3:
        new_navigation_drawer_title = rootGetters.specificMachine(
          new_active_element_id
        ).name;
        commit("setOverviewActiveElement", {
          new_active_element_id,
          new_active_element_type,
          new_navigation_drawer_title,
        });
        break;

      case 4:
        new_navigation_drawer_title = rootGetters.specificSensor(
          new_active_element_id
        ).name;
        commit("setOverviewActiveElement", {
          new_active_element_id,
          new_active_element_type,
          new_navigation_drawer_title,
        });
        break;
    }
  },
};

const mutations = {
  setOverviewActiveElement: (
    state: any,
    {
      new_active_element_id,
      new_active_element_type,
      new_navigation_drawer_title,
    }: {
      new_active_element_id: string;
      new_active_element_type: activeElementType;
      new_navigation_drawer_title: string;
    }
  ) => (
    (state.active_element_id = new_active_element_id),
    (state.active_element_type = new_active_element_type),
    (state.navigation_drawer_title = new_navigation_drawer_title)
  ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
