<!-- 
    Title: UserDialog
  
    Description: Dialog for displaying information about the currently logged in user
-->

<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title> Benutzer: </v-card-title>

      <v-card-text>
        <!-- User Login -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="getAppLoggedInUser.login"
              label="User ID"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- First Name -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="getAppLoggedInUser.first_name"
              label="Vorname"
              readonly
            ></v-text-field>
          </v-col>

          <!-- Last Name -->
          <v-col>
            <v-text-field
              v-model="getAppLoggedInUser.last_name"
              label="Nachname"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <!-- Change Password Button -->
        <v-btn text color="success" @click="openPwResetDialog()"
          >Password Ändern</v-btn
        >
        <v-spacer></v-spacer>

        <!-- Close Button -->
        <v-btn color="grey" text @click="$emit('closeUserDialog')">
          Abbrechen
        </v-btn>

        <!-- Logout Button -->
        <v-btn
          color="red"
          text
          @click="
            logOut();
            $emit('closeUserDialog');
          "
        >
          Ausloggen
        </v-btn>
      </v-card-actions>
    </v-card>
    <EditPasswordDialog
      :display="display_edit_pw_reset_dialog"
      :edited_user="edited_user"
      :edited_user_index="edited_user_index"
      @closeEditPasswordDialog="closeEditPasswordDialog"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditPasswordDialog from "../adminCenter/components/adminUser/EditPasswordDialog.vue";

export default {
  name: "UserDialog",

  components: {
    EditPasswordDialog,
  },

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
  },

  computed: {
    ...mapGetters([
      "getAppLoggedInUser",
      "allRoles",
      "specificUser",
      "allUsers",
    ]),
  },

  data: () => ({
    display_edit_pw_reset_dialog: false, //determines weather the dialog is displayed
    edited_user_index: -1, // the index of the current user, used for password changes

    // the current user
    edited_user: {
      _id: "",
      created_by: "",
      creation_date: new Date(),
      first_name: "",
      last_login: new Date(),
      last_name: "",
      login: "",
      password: "",
      role: "",
      status: 0,
    },

    // standard data of a user, used to reset edited_user
    standard_user: {
      _id: "",
      created_by: "",
      creation_date: new Date(),
      first_name: "",
      last_login: new Date(),
      last_name: "",
      login: "",
      password: "",
      role: "",
      status: 0,
    },
  }),

  methods: {
    ...mapActions(["logOut"]),

    /**
     * Title: openPwResetDialog()
     * Description: opens the password reset dialog
     *
     */
    openPwResetDialog() {
      this.edited_user_index = this.allUsers.indexOf(this.getAppLoggedInUser);
      Object.assign(this.edited_user, this.getAppLoggedInUser);
      this.display_edit_pw_reset_dialog = true;
    },

    /**
     * Title: closeEditPasswordDialog()
     * Description: closes the password reset dialog
     *
     */
    closeEditPasswordDialog() {
      this.edited_user_index = -1;
      Object.assign(this.edited_user, this.standard_user);
      this.display_edit_pw_reset_dialog = false;
    },
  },
};
</script>
