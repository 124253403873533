import axios from "axios";
import { ObjectId } from "mongodb";
import { Maintenance } from "../../types/maintenances";

const url = "api/maintenance/";

const state = {
  maintenances: [],
};

const getters = {
  /**
   * Title: allMaintenances()
   *
   * Description: Returns all Maintenances as an array
   */
  allMaintenances: (state: any): Maintenance[] => state.maintenances,

  /**
   * Title: specificMaintenance()
   *
   * Description: Returns a specific Maintenance
   *
   * @param {ObjectId} maintenance_id the id of the maintenance that should be returned
   *
   */
  specificMaintenance:
    (state: any) =>
    (maintenance_id: ObjectId): Maintenance => {
      return state.maintenances.find(
        (maintenance: Maintenance) => maintenance._id === maintenance_id
      );
    },
};

const actions = {
  /**
   * Title: fetchMaintenances()
   *
   * Route: GET /maintenance
   * Description: Fetches data for all Maintenances
   */

  async fetchMaintenances({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setMaintenances", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Baugruppen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newMachineMaintenance()
   *
   * Route: Post /api/machine/startMaintenance/.../
   * Description: Adds new Maintenance to the Database
   *
   * @param {String} name the name of new maintenance
   * @param {String} description the description of the new maintenance
   * @param {String} type the id of the type of maintenance
   * @param {ObjectId} machine_id the id of the machine of the maintenance
   *
   */
  async newMachineMaintenance(
    { commit, dispatch, rootGetters }: any,
    {
      name,
      description,
      type,
      machine_id,
    }: {
      name: string;
      description: string;
      type: ObjectId;
      machine_id: ObjectId;
    }
  ) {
    const post_url = "api/machine/startMaintenance/" + machine_id;
    try {
      const response = await axios.post(post_url, {
        name,
        description,
        type,
        created_by: rootGetters.getAppLoggedInUser._id,
      });

      //Commit Maintenance to State
      commit("addMaintenance", response.data);

      //Add Maintenance ID to Maintenance History
      const machine_index = rootGetters.allMachines.indexOf(
        rootGetters.specificMachine(machine_id)
      );
      commit("addToMachineMaintenanceHistory", {
        maintenance_id: response.data._id,
        machine_index,
      });

      //Confirmation Message
      dispatch(
        "newNotification",
        {
          text: "Wartung angelegt.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Wartung konnte nicht angelegt werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newMachineMaintenance()
   *
   * Route: Post /api/machine/startMaintenance/.../
   * Description: Adds new Maintenance to the Database
   *
   * @param {String} name the name of new maintenance
   * @param {String} description the description of the new maintenance
   * @param {String} type the id of the type of maintenance
   * @param {ObjectId} machine_id the id of the machine of the maintenance
   *
   */
  async newSensorMaintenance(
    { commit, dispatch, rootGetters }: any,
    {
      name,
      description,
      type,
      sensor_id,
    }: {
      name: string;
      description: string;
      type: ObjectId;
      sensor_id: ObjectId;
    }
  ) {
    const post_url = "api/sensor/startMaintenance/" + sensor_id;
    try {
      const response = await axios.post(post_url, {
        name,
        description,
        type,
        created_by: rootGetters.getAppLoggedInUser._id,
      });

      //Commit Maintenance to State
      commit("addMaintenance", response.data);

      //Add Maintenance ID to Maintenance History
      const sensor_index = rootGetters.allSensors.indexOf(
        rootGetters.specificSensor(sensor_id)
      );
      commit("addToSensorMaintenanceHistory", {
        maintenance_id: response.data._id,
        sensor_index,
      });

      //Confirmation Message
      dispatch(
        "newNotification",
        {
          text: "Wartung angelegt.",
          type: "success",
          timeout: 3000,
          required_right: "maintenance",
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Wartung konnte nicht angelegt werden.",
          type: "error",
          timeout: 3000,
          required_right: "maintenance",
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newMachineMaintenance()
   *
   * Route: Post /api/maintenance/finish/.../
   * Description: Fnishes a maintenance
   *
   * @param {String} maintenance_id the id of the maintenance
   *
   */
  async finishMaintenance({ commit, dispatch }: any, maintenance_id: ObjectId) {
    try {
      console.log(maintenance_id)
      const patch_url = url + "finish/" + maintenance_id;
      const response = await axios.patch(patch_url);
      console.log(response)
      dispatch(
        "newNotification",
        {
          text: "Wartung beendet.",
          type: "success",
          timeout: 3000,
          required_right: "maintenance",
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Wartung konnte nicht beendet werden.",
          type: "error",
          timeout: 3000,
          required_right: "maintenance",
        },
        { root: true }
      );
    }
  },

  /**
   * Title: mqttMaintenanceStatusUpdate()
   *
   * Description: Updates the Status of a maintenance received by mqtt
   *
   * @param {Sensor} maintenance_id the id of the maintenance
   * @param {Number} maintenance_status the status of the maintenance
   */
  mqttMaintenanceStatusUpdate(
    { commit, getters }: any,
    {
      maintenance_id,
      maintenance_status,
    }: { maintenance_id: ObjectId; maintenance_status: number }
  ) {
    const maintenance_index = getters.allMaintenances.indexOf(
      getters.specificMaintenance(maintenance_id)
    );
    commit("setMaintenanceStatus", { maintenance_index, maintenance_status });
  },

  /**
   * Title:    mqttMaintenanceTimeStartedUpdate()
   *
   * Description: Updates the time_started of a maintenance received by mqtt
   *
   * @param {Sensor} maintenance_id the id of the maintenance
   * @param {Number} time_started the start time of the maintenance
   */
  mqttMaintenanceTimeStartedUpdate(
    { commit, getters }: any,
    {
      maintenance_id,
      time_started,
    }: { maintenance_id: ObjectId; time_started: Date }
  ) {
    const maintenance_index = getters.allMaintenances.indexOf(
      getters.specificMaintenance(maintenance_id)
    );
    commit("setMaintenanceTimeStarted", { maintenance_index, time_started });
  },

  /**
   * Title:    mqttMaintenanceTimeStartedUpdate()
   *
   * Description: Updates the time_started of a maintenance received by mqtt
   *
   * @param {Sensor} maintenance_id the id of the maintenance
   * @param {Number} time_finished the end time of the maintenance
   */
  mqttMaintenanceTimeFinishedUpdate(
    { commit, getters }: any,
    {
      maintenance_id,
      time_finished,
    }: { maintenance_id: ObjectId; time_finished: Date }
  ) {
    console.log(1)
    const maintenance_index = getters.allMaintenances.indexOf(
      getters.specificMaintenance(maintenance_id)
    );
    commit("setMaintenanceTimeFinished", { maintenance_index, time_finished });
  },
};

const mutations = {
  setMaintenances: (state: any, maintenances: Maintenance[]) =>
    (state.maintenances = maintenances),
  addMaintenance: (state: any, maintenance: Maintenance) =>
    state.maintenances.push(maintenance),
  setMaintenanceStatus: (state: any, payload: any) =>
    (state.maintenances[payload.maintenance_index].status =
      payload.maintenance_status.valueOf()),
  setMaintenanceTimeStarted: (state: any, payload: any) =>
    (state.maintenances[payload.maintenance_index].time_started =
      payload.time_started.valueOf()),
  setMaintenanceTimeFinished: (state: any, payload: any) =>
    (state.maintenances[payload.maintenance_index].time_finished =
      payload.time_finished.valueOf()),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
