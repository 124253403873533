






import { mapActions } from 'vuex';
import HelloWorld from "../components/general/Hometext.vue";

export default {
  name: "Home",

  components: {
    HelloWorld,
  },

  methods: {
    ...mapActions(["startSensor", "stopSensor", "startMachine", "stopMachine"]),
  }
};
