import axios from "axios";
import { ObjectId } from "mongodb";
import { ProductionOrder } from "../../../types/productionOrder";

const url = "api/simulation/";

const state = {
  sim_productionsorders: [],
};

const getters = {
  /**
   * Title: allSimulations()
   *
   * Description: Returns all Simulations as an array
   */
  allSimulations: (state: any): ProductionOrder[] =>
    state.sim_productionsorders,

  /**
   * Title: specificProductionOrder()
   *
   * Description: Returns a specific ProductionOrder
   *
   * @param {ObjectId} simulation_id the id of the simulation that should be returned
   *
   */
  specificSimulation:
    (state: any) =>
    (simulation_id: ObjectId): ProductionOrder => {
      return state.sim_productionsorders.find(
        (simulation: ProductionOrder) => simulation._id === simulation_id
      );
    },
};

const actions = {
  /**
   * Title: fetchSimulations()
   *
   * Route: GET /simulation
   * Description: Fetches data for all Simulations
   */

  async fetchSimulations({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setSimulations", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Produktionsaufträge konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newProductionOrder()
   *
   * Route: Post /simulation/
   * Description: Adds new ProductionOrder to the Database
   *
   * @param {String} name the name of new simulation
   * @param {String} description the description of the new simulation
   * @param {String} location the location of the new simulation
   * @param {ObjectId} type the type of the new simulation
   *
   * Info: Every other attribute of a simulation is beeing added by the API and returned as a response which then is pushed to the state
   */
  async newSimulation(
    { commit, dispatch, rootGetters }: any,
    {
      name,
      productionOrder,
      cloneLayout,
      description,
    }: {
      name: string;
      productionOrder: { subassembly_type: ObjectId; amount: number }[];
      cloneLayout: boolean;
      description: string;
    }
  ) {
    const created_by = rootGetters.getAppLoggedInUser._id;
    dispatch("changeAppLoadingStatus", true);
    try {
      const response = await axios.post(url, {
        name,
        productionOrder,
        cloneLayout,
        description,
        created_by,
      });
      console.log(response.data);
      commit("addSimulation", response.data);
      // Update Assemblys as new Assemblys have been added
      dispatch("fetchAssemblys");
      dispatch(
        "newNotification",
        {
          text: "Simulierter Produktionsauftrag gespeichert",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierter Produktionsauftrag konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
    dispatch("changeAppLoadingStatus", false);
  },

  /**
   * Title: newProductionOrder()
   *
   * Route: Delete /simulation/id
   * Description: Deletes a ProductionOrder from the Database
   *
   * @param {ProductionOrder} simulation the simulation that is being deleted
   *
   */
  async deleteSimulation(
    { commit, dispatch }: any,
    simulation: ProductionOrder
  ) {
    dispatch("changeAppLoadingStatus", true);
    try {
      const delete_url = url + simulation._id;
      const response = await axios.delete(delete_url);
      commit("removeSimulation", simulation);
      dispatch(
        "newNotification",
        {
          text: "Simulierter Produktionsauftrag wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
      dispatch("fetchAssemblys");
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierter Produktionsauftrag konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
    dispatch("changeAppLoadingStatus", false);
  },

  /**
   * Title: startSimulation()
   *
   * Description: trys to start the latest simulation
   */
  async startSimulation({ dispatch }: any) {
    try {
      const patch_url = url + "command";
      const response = await axios.patch(patch_url, { command: "start" });
      dispatch(
        "newNotification",
        {
          text: "Simulation wurde gestartet.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulation konnte nicht gestartet werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: mqttProductionOrderStatusUpdate()
   *
   * Description: Updates the Status of a simulation received by mqtt
   *
   * @param {Sensor} simulation_id the id of the simulation
   * @param {Number} simulation_status the status of the simulation
   */
  mqttSimulationStatusUpdate(
    { commit, getters }: any,
    {
      simulation_id,
      simulation_status,
    }: { simulation_id: ObjectId; simulation_status: number }
  ) {
    const simulation_index = getters.allSimulations.indexOf(
      getters.specificSimulation(simulation_id)
    );
    commit("setSimulationStatus", {
      simulation_index,
      simulation_status,
    });
  },
};

const mutations = {
  setSimulations: (state: any, sim_productionsorders: ProductionOrder[]) =>
    (state.sim_productionsorders = sim_productionsorders),
  addSimulation: (state: any, simulation: ProductionOrder) =>
    state.sim_productionsorders.push(simulation),
  removeSimulation: (state: any, simulation: ProductionOrder) =>
    state.sim_productionsorders.splice(
      state.sim_productionsorders.indexOf(simulation),
      1
    ),
  updateSimulation: (state: any, payload: any) =>
    Object.assign(
      state.sim_productionsorders[payload.simulation_index],
      payload.simulation
    ),
  setSimulationStatus: (state: any, payload: any) =>
    (state.sim_productionsorders[payload.simulation_index].status =
      payload.simulation_status.valueOf()),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
