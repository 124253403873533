import axios from "axios";
import { ObjectId } from "mongodb";
import { Role } from "../../types/role";

const url = "api/role/";

const state = {
  roles: [],
};

const getters = {
  /**
   * Title: allRoles()
   *
   * Description: Returns all Roles as an array
   */
  allRoles: (state: any):Role[] => state.roles,

  /**
   * Title: specificRole()
   *
   * Description: Returns a specific Role
   * 
   * @param {ObjectId} role_id the id of the role that should be returned
   * 
   */
  specificRole: (state: any) => (role_id: ObjectId):Role => {
    return state.roles.find(
      (role: Role) => role._id === role_id
    );
  },
};

const actions = {
  /**
   * Title: fetchRoles()
   *
   * Route: GET /role
   * Description: Fetches data for all Roles
   */

  async fetchRoles({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setRoles", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Rollen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newRole()
   *
   * Route: Post /role/
   * Description: Adds new Role to the Database
   *
   * @param {String} name the name of new role
   * @param {String} description the description of the new role
   * @param {String} location the location of the new role
   * @param {ObjectId} type the type of the new role
   *
   * Info: Every other attribute of a role is beeing added by the API and returned as a response which then is pushed to the state
   */
  async newRole(
    { commit, dispatch, rootGetters }: any,
    { name, description, rights }: { name: string, description: string, rights: []}
  ) {
    try {
      const response = await axios.post(url, {
        name,
        description,
        rights,
        created_by: rootGetters.getAppLoggedInUser._id
      });
      commit("addRole", response.data);
      dispatch(
        "newNotification",
        {
          text: "Rolle gespeichert",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Rolle konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newRole()
   *
   * Route: Delete /role/id
   * Description: Deletes a Role from the Database
   *
   * @param {Role} role the role that is being deleted
   *
   */
  async deleteRole({ commit, dispatch }: any, role: Role) {
    try {
      // const delete_url = url + role._id
      // const response = await axios.delete(delete_url);
      commit("removeRole", role);
      dispatch(
        "newNotification",
        {
          text: "Rolle wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Rolle konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editRole()
   *
   * Route: Put /role/id
   * Description: Pushes the new state of a role to the databas
   *
   * @param {Role} role the new state role that is being edited
   * @param {Number} role_index the index of the machin within the state array, transmitted as the new state of the role would not allow to find the index here as new state is different from the old state
   *
   * Info: Every other attribute of a role is beeing added by the API and returned as a response which then is pushed to the state
   */

  async editRole(
    { commit, dispatch }: any,
    { role, role_index }: {role: Role, role_index: number}
  ) {
    try {
      console.log(role)
      const put_url = url + role._id;
      const response = await axios.put(put_url, role);
      const data = response.data;
      commit("updateRole", { role: data, role_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },
};

const mutations = {
  setRoles: (state: any, roles: Role[]) => (state.roles = roles),
  addRole: (state: any, role: Role) => state.roles.push(role),
  removeRole: (state: any, role: Role) =>
    state.roles.splice(state.roles.indexOf(role), 1),
  updateRole: (state: any, payload: any) =>
    (state.roles[payload.role_index] = payload.role)
};

export default {
  state,
  getters,
  actions,
  mutations,
};
