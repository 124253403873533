<!-- 
    Title: NotificationDialog
  
    Description: Dialog for displaying all current Notifications
-->

<template>
  <v-dialog v-model="display" persistent width="75%" height="75%">
    <v-card tile>
      <v-divider></v-divider>
      <v-card-text>
        <!-- Dialog Title -->
        <h3 class="mt-5">Neue Nachrichten</h3>

        <!-- Text If no current notifications -->
        <p class="mt-5" v-if="allNotifications.length === 0">
          Keine neuen Nachrichten
        </p>

        <!-- Notification Table -->
        <v-simple-table v-if="allNotifications.length != 0">
          <thead>
            <tr>
              <th>Typ</th>
              <th>Datum</th>
              <th>Uhrzeit</th>
              <th>Nachricht</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in allNotifications" :key="item.id">
              <!-- Notification Type Chip -->
              <td>
                <v-chip :color="item.type" dark>
                  <v-icon v-if="item.type === 'success'" left>
                    {{ navigationIcons.success }}
                  </v-icon>
                  <v-icon v-if="item.type === 'info'" left>{{
                    navigationIcons.info
                  }}</v-icon>
                  <v-icon v-if="item.type === 'warning'" left>{{
                    navigationIcons.warning
                  }}</v-icon>
                  <v-icon v-if="item.type === 'error'" left>{{
                    navigationIcons.error
                  }}</v-icon>
                  {{ notificationTypeToText(item.type) }}
                </v-chip>
              </td>

              <!-- Notification Date -->
              <td>{{ item.date.toLocaleDateString() }}</td>

              <!-- Notification Time -->
              <td>{{ item.date.toLocaleTimeString() }}</td>

              <!-- Notification Text -->
              <td>{{ item.text }}</td>

              <!-- Button to check off a current Notification -->
              <td>
                <div class="d-flex justify-end">
                  <v-icon small class="mr-2" @click="checkNotification(item)">{{
                    navigationIcons.check
                  }}</v-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <!-- Subheading for checked notifications -->
        <h3 class="mt-10">Alte Nachrichten</h3>

        <!-- Text if no checked notifications -->
        <p class="mt-5" v-if="allCheckedNotifications.length === 0">
          Keine alten Nachrichten
        </p>

        <!-- Data Table for checked notifications -->
        <v-simple-table v-if="allCheckedNotifications.length != 0" dense>
          <thead>
            <tr>
              <th>Typ</th>
              <th>Datum</th>
              <th>Uhrzeit</th>
              <th>Nachricht</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in allCheckedNotifications" :key="item.id">
              <!-- Notification Type Chip -->
              <td>
                <v-chip :color="grey" dark small>
                  <v-icon v-if="item.type === 'success'" left small>
                    {{ navigationIcons.success }}
                  </v-icon>
                  <v-icon v-if="item.type === 'info'" left small>{{
                    navigationIcons.info
                  }}</v-icon>
                  <v-icon v-if="item.type === 'warning'" left small>{{
                    navigationIcons.warning
                  }}</v-icon>
                  <v-icon v-if="item.type === 'error'" left small>{{
                    navigationIcons.error
                  }}</v-icon>
                  {{ notificationTypeToText(item.type) }}
                </v-chip>
              </td>

              <!-- Notification Date -->
              <td>{{ item.date.toLocaleDateString() }}</td>

              <!-- Notification Time -->
              <td>{{ item.date.toLocaleTimeString() }}</td>

              <!-- Notification Text -->
              <td>{{ item.text }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>

      <!-- Dialog Close Button -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn right text @click="$emit('closeNotificationDialog')">
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import helperMixins from "../../mixins/helperMixins";

export default {
  name: "NotificationDialog",

  mixins: [helperMixins],

  data: () => ({
    notification_search: "", //search string

    // Header data of the notification table
    notification_data_header: [
      { text: "Typ:", value: "type" },
      { text: "Datum:", value: "date" },
      { text: "Uhrzeit:", value: "time" },
      { text: "Text", value: "text" },
      { text: "", value: "checked_off" },
    ],
  }),

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
  },

  computed: {
    ...mapGetters([
      "allNotifications",
      "navigationIcons",
      "allCheckedNotifications",
    ]),
  },

  methods: {
    ...mapActions(["checkNotification"]),
  },
};
</script>
