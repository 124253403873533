<!-- 
    Title: LoginDialog
  
    Description: Used to ask a user for a login name and a password
-->

<template>
  <v-dialog v-model="display" persistent max-width="600px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Login</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="form_validation">
          <!-- Username -->
          <v-row>
            <v-col>
              <v-text-field
                v-model="login"
                label="User ID"
                :rules="[() => !!login || 'Pflichtfeld']"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Password -->
          <v-row>
            <v-col>
              <v-text-field
                v-model="password"
                :type="show_pw ? 'text' : 'password'"
                :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show_pw = !show_pw"
                @keyup.enter="tryLogIn()"
                label="Passwort"
                :rules="[() => !!password || 'Pflichtfeld']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <!-- Login Button -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          text
          @click="tryLogIn()"
          :disabled="!form_validation"
        >
          Einloggen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoginDialog",

  props: {
    display: Boolean, // Determines if the Dialog is beeing displayed
  },

  computed: {
    ...mapGetters(["getAppLoggedInUser", "allUsers"]),
  },

  methods: {
    ...mapActions(["logIn", "newNotification"]),

    /**
     * Title: tryLogIn()
     *
     * Description: Trys to log the user in, throws error if login not possible
     */

    tryLogIn() {
      const try_user = this.allUsers.find((user) => user.login === this.login);
      if (try_user === undefined) {
        this.newNotification({
          text: "User ID oder Password nicht korrekt",
          type: "error",
          timeout: 3000,
        });
      } else if (try_user.password === this.password) {
        this.logIn(try_user);
        this.password = "";
        this.login = "";
        this.show_pw = false;
      } else
        this.newNotification({
          text: "User ID oder Password nicht korrekt",
          type: "error",
          timeout: 3000,
        });
    },
  },

  data: () => ({
    login: "", // The Login Name
    password: "", // The password
    show_pw: false, // Determines weather the password is shown in clear text
    form_validation: false, // Validation Data of the input form
  }),
};
</script>
