<!-- 
    Title: EditPasswordDialog
  
    Description: Dialog for editing a password of a user
-->
<template>
  <v-dialog v-model="display" persistent max-width="800px">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title>
        <span class="text-h5">Neues Passwort für {{ edited_user.login }}</span>
      </v-card-title>

      <!-- Input Fomr -->
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="form_validation">
            <!-- New Password Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="new_password"
                  label="Passwort"
                  :type="show_pw ? 'text' : 'password'"
                  :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[
                    () => !!new_password || 'Pflichtfeld',
                    () =>
                      (!!new_password && new_password.length >= 12) ||
                      'Mindestens 12 Zeichen!',
                  ]"
                  @click:append="show_pw = !show_pw"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Passowrd Confirmation Input -->
            <v-row>
              <v-col>
                <v-text-field
                  v-model="new_password_confirm"
                  label="Passwort bestätigen"
                  :type="show_pw ? 'text' : 'password'"
                  :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[
                    () => !!new_password_confirm || 'Pflichtfeld',
                    () =>
                      (!!new_password_confirm &&
                        new_password_confirm.length >= 12) ||
                      'Mindestens 12 Zeichen!',
                  ]"
                  @click:append="show_pw = !show_pw"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Close Button -->
        <v-btn
          color="red"
          text
          @click="
            $emit('closeEditPasswordDialog');
            $refs.form.reset();
          "
        >
          Abbrechen
        </v-btn>

        <!-- Save Button -->
        <v-btn
          color="green"
          text
          @click="saveNewPassword"
          :disabled="!form_validation"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import User from "../../../../types/user";
import { mapActions } from "vuex";
export default {
  name: "EditPasswordDialog",

  props: {
    display: Boolean, // Determines weather the Dialog is beeing displayed
    edited_user: User, // The user whos password is beeing edited
    edited_user_index: Number, // The index of the user within the state-management
  },

  data: () => ({
    new_password: "", // the new password
    new_password_confirm: "", // the new confirmed password
    show_pw: false, // weather the password is displayed in clear text
    form_validation: false, // validation data of the input form
  }),

  methods: {
    ...mapActions(["editUser", "newNotification"]),
    /**
     * Title: saveEdit()
     * Description: saves the new password
     *
     */
    saveNewPassword() {
      // Validate Input Form
      this.form_validation = this.$refs.form.validate();

      // Check Validation
      if (this.form_validation === true) {
        if (this.new_password === this.new_password_confirm) {
          this.edited_user.password = this.new_password;

          //update User
          this.editUser({
            user: this.edited_user,
            user_index: this.edited_user_index,
          });

          //Close Dialog & send Notification
          this.$emit("closeEditPasswordDialog");

          // Emit success Notifaction to user
          this.newNotification({
            text: "Passwort gespeichert",
            type: "success",
            timeout: 4000,
          });

          // Reset Form
          this.$refs.form.reset();
        } else {
          // Emit error Notification
          this.newNotification({
            text: "Passwörter stimmen nicht überein!",
            type: "error",
            timeout: 4000,
          });
        }
      }
    },
  },
};
</script>