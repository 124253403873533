import axios from "axios";
import { ObjectId } from "mongodb";
import { Machine, MachineStatus } from "../../types/machine";

const url = "api/machine/";

const state = {
  machines: [],
};

const getters = {
  /**
   * Title: allMachines()
   *
   * Description: Returns all Machines as an array
   */
  allMachines: (state: any): Machine[] => state.machines,

  /**
   * Title: specificMachine()
   *
   * Description: Returns a specific Machine
   *
   * @param {ObjectId} machine_id the id of the machine that should be returned
   *
   */
  specificMachine:
    (state: any) =>
    (machine_id: ObjectId): Machine => {
      return state.machines.find(
        (machine: Machine) => machine._id === machine_id
      );
    },
};

const actions = {
  /**
   * Title: fetchMachines()
   *
   * Route: GET /machine
   * Description: Fetches data for all Machines
   */

  async fetchMachines({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setMachines", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Maschinen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newMachine()
   *
   * Route: Post /machine/
   * Description: Adds new Machine to the Database
   *
   * @param {String} name the name of new machine
   * @param {String} description the description of the new machine
   * @param {String} location the location of the new machine
   * @param {ObjectId} type the type of the new machine
   *
   * Info: Every other attribute of a machine is beeing added by the API and returned as a response which then is pushed to the state
   */
  async newMachine(
    { commit, dispatch }: any,
    {
      name,
      description,
      location,
      type,
    }: { name: string; description: string; location: string; type: ObjectId }
  ) {
    try {
      const response = await axios.post(url, {
        name,
        description,
        location,
        type,
        components: {
          sensors: [],
        },
      });
      commit("addMachine", response.data);
      dispatch(
        "newNotification",
        {
          text: "Maschine gespeichert",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
      dispatch("fetchOverviewNavigationTree");
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Maschine konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newMachine()
   *
   * Route: Delete /machine/id
   * Description: Deletes a Machine from the Database
   *
   * @param {Machine} machine the machine that is being deleted
   *
   */
  async deleteMachine({ commit, dispatch }: any, machine: Machine) {
    try {
      // const delete_url = url + machine._id
      // const response = await axios.delete(delete_url);
      commit("removeMachine", machine);
      dispatch(
        "newNotification",
        {
          text: "Maschine wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Maschine konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editMachine()
   *
   * Route: Put /machine/id
   * Description: Pushes the new state of a machine to the databas
   *
   * @param {Machine} machine the new state machine that is being edited
   * @param {Number} machine_index the index of the machin within the state array, transmitted as the new state of the machine would not allow to find the index here as new state is different from the old state
   *
   * Info: Every other attribute of a machine is beeing added by the API and returned as a response which then is pushed to the state
   */

  async editMachine(
    { commit, dispatch }: any,
    { machine, machine_index }: { machine: Machine; machine_index: number }
  ) {
    try {
      const put_url = url + machine._id;
      const response = await axios.put(put_url, machine);
      const data = response.data;
      commit("updateMachine", { machine: data, machine_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
      dispatch("fetchOverviewNavigationTree");
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: addSensorToMachine()
   *
   * Route: Put /machine/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} machine_id the id of the machine that is being edited
   * @param {Number} sensor_id the id of the sensor that is beeing added
   *
   */

  async addSensorToMachine(
    { commit, dispatch, getters }: any,
    { machine_id, sensor_id }: { machine_id: ObjectId; sensor_id: ObjectId }
  ) {
    try {
      const patch_url = url + machine_id + "/addSensor";
      const machine_index = getters.allMachines.indexOf(
        getters.specificMachine(machine_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sensor_id,
      });
      const data = response.data;
      commit("updateMachine", { machine: data, machine_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: removeSensorFromMachine()
   *
   * Route: Put /machien/id
   * Description: Adds a new Sensor to a machine
   *
   * @param {Factory} machine_id the id of the machine that is being edited
   * @param {Number} sensor_id the id of the sensor that is beeing added
   *
   */

  async removeSensorFromMachine(
    { commit, dispatch, getters }: any,
    { machine_id, sensor_id }: { machine_id: ObjectId; sensor_id: ObjectId }
  ) {
    try {
      const patch_url = url + machine_id + "/removeSensor";
      const machine_index = getters.allMachines.indexOf(
        getters.specificMachine(machine_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sensor_id,
      });
      const data = response.data;
      commit("updateMachine", { machine: data, machine_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: startMachine()
   *
   * Description: trys to start a Machine
   *
   * @param {ObjectId} machine_id the id of the machine
   */
  async startMachine(
    { dispatch }: any,
    { machine_id }: { machine_id: ObjectId }
  ) {
    try {
      const patch_url = url + "command/" + machine_id;
      const response = await axios.patch(patch_url, { command: "start" });
      dispatch(
        "newNotification",
        {
          text: "Maschine wurde gestartet.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Maschine konnte nicht gestartet werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: stopMachine()
   *
   * Description: trys to stop a Machine
   *
   * @param {ObjectId} machine_id the id of the machine
   */
  async stopMachine(
    { dispatch }: any,
    { machine_id }: { machine_id: ObjectId }
  ) {
    try {
      const patch_url = url + "command/" + machine_id;
      const response = await axios.patch(patch_url, { command: "stop" });
      console.log(response);
      dispatch(
        "newNotification",
        {
          text: "Maschine wurde gestoppt.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Maschine konnte nicht gestopt werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: mqttMachineStatusUpdate()
   *
   * Description: Updates the Status of a machine received by mqtt
   *
   * @param {ObjectId} machine_id the id of the machine
   * @param {Number} machine_status the status of the machine
   */
  mqttMachineStatusUpdate(
    { commit, getters }: any,
    {
      machine_id,
      machine_status,
    }: { machine_id: ObjectId; machine_status: MachineStatus }
  ) {
    const machine_index = getters.allMachines.indexOf(
      getters.specificMachine(machine_id)
    );
    commit("setMachineStatus", { machine_index, machine_status });
  },

  /**
   * Title: mqttMachineStatusUpdate()
   *
   * Description: Updates the Status of a machine received by mqtt
   *
   * @param {ObjectId} machine_id the id of the machine
   * @param {Number} machine_status the status of the machine
   */
  mqttMachineCurrentlyWorkingOnUpdate(
    { commit, getters }: any,
    {
      machine_id,
      currently_working_on,
    }: { machine_id: ObjectId; currently_working_on: ObjectId | null | "null"}
  ) {
    const machine_index = getters.allMachines.indexOf(
      getters.specificMachine(machine_id)
    );

    // Currently Working on can be null so must be filtered out for the production history
    if (currently_working_on != "null") {
      commit("addMachineProductionHistory", {
        machine_index,
        currently_working_on,
      });
    }
    commit("setMachineCurrentlyWorkingOn", {
      machine_index,
      currently_working_on,
    });
  },

  /**
   * Title: mqttMachineOperatingTimeUpdate()
   *
   * Description: Updates the Status of a machine received by mqtt
   *
   * @param {ObjectId} machine_id the id of the machine
   * @param {Number} machine_status the status of the machine
   */
  mqttMachineOperatingTimeUpdate(
    { commit, getters }: any,
    {
      machine_id,
      operating_time,
    }: { machine_id: ObjectId; operating_time: number }
  ) {
    const machine_index = getters.allMachines.indexOf(
      getters.specificMachine(machine_id)
    );
    commit("setMachineOperatingTime", { machine_index, operating_time });
  },

  /**
   * Title: mqttSensorValueUpdate()
   *
   * Description: Updates the Value of a sensor received by mqtt
   *
   * @param {Sensor} sensor_id the id of the sensor
   * @param {Number} assigned the value of the sensor
   */
  mqttMachineAssignedUpdate(
    { commit, getters }: any,
    { machine_id, assigned }: { machine_id: ObjectId; assigned: boolean }
  ) {
    const machine_index = getters.allMachines.indexOf(
      getters.specificMachine(machine_id)
    );
    commit("updateMachineAssigned", { machine_index, assigned });
  },

  mqttMachineCurrentThroughputUpdate(
    { commit, getters }: any,
    {
      machine_id,
      current_throughput,
    }: { machine_id: ObjectId; current_throughput: number }
  ) {
    const machine_index = getters.allMachines.indexOf(
      getters.specificMachine(machine_id)
    );
    commit("setMachineCurrentThroughput", {
      machine_index,
      current_throughput,
    });
  },

  mqttMachineAverageProcessingTimeUpdate(
    { commit, getters }: any,
    {
      machine_id,
      average_processing_time,
    }: { machine_id: ObjectId; average_processing_time: number }
  ) {
    const machine_index = getters.allMachines.indexOf(
      getters.specificMachine(machine_id)
    );
    commit("setMachineAverageProcessingTime", {
      machine_index,
      average_processing_time,
    });
  },
};

const mutations = {
  setMachines: (state: any, machines: Machine[]) => (state.machines = machines),
  addMachine: (state: any, machine: Machine) => state.machines.push(machine),
  removeMachine: (state: any, machine: Machine) =>
    state.machines.splice(state.machines.indexOf(machine), 1),
  updateMachine: (state: any, payload: any) =>
    (state.machines[payload.machine_index] = payload.machine),
  setMachineCurrentlyWorkingOn: (state: any, payload: any) =>
    (state.machines[payload.machine_index].currently_working_on =
      payload.currently_working_on.valueOf()),
  addMachineProductionHistory: (state: any, payload: any) =>
    state.machines[payload.machine_index].production_history.push(
      payload.currently_working_on.valueOf()
    ),
  setMachineStatus: (state: any, payload: any) =>
    (state.machines[payload.machine_index].current_status =
      payload.machine_status.valueOf()),
  setMachineOperatingTime: (state: any, payload: any) =>
    (state.machines[payload.machine_index].operating_time =
      payload.operating_time.valueOf()),
  setMachineCurrentThroughput: (state: any, payload: any) => (
    state.machines[payload.machine_index].throughput_history.push({
      time: new Date().toISOString(),
      value: payload.current_throughput.valueOf(),
    }),
    (state.machines[payload.machine_index].current_throughput =
      payload.current_throughput.valueOf())
  ),
  setMachineAverageProcessingTime: (state: any, payload: any) => (
    state.machines[payload.machine_index].average_processing_time_history.push({
      time: new Date().toISOString(),
      value: payload.average_processing_time.valueOf(),
    }),
    (state.machines[payload.machine_index].average_processing_time =
      payload.average_processing_time.valueOf())
  ),
  updateMachineAssigned: (state: any, payload: any) =>
    (state.machines[payload.machine_index].assigned =
      payload.assigned.valueOf()),
  addToMachineMaintenanceHistory: (state: any, payload: any) =>
    state.machines[payload.machine_index].maintenance_history.push(
      payload.maintenance_id
    ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
