<!-- 
    Title: Notification
  
    Description: the Notification Element used to display a new notification
-->
<template>
  <div>
    <v-snackbar
      v-for="(notification, index) in allNotifications.filter(
        (a) => a.displayed
      )"
      v-model="notification.displayed"
      right
      width="35%"
      multi-line
      app
      top
      tile
      ref="alerts"
      :key="notification.id"
      :color="notification.type"
      :timeout="notification.timeout"
      :style="`top: ${index * 80 + 8}px`"
    >
      <v-layout align-center pr-4>
        <!-- Notification Icon -->
        <v-icon large v-if="notification.type === 'success'" class="mr-4">
          {{ navigationIcons.success }}
        </v-icon>
        <v-icon large v-if="notification.type === 'info'" class="mr-2">{{
          navigationIcons.info
        }}</v-icon>
        <v-icon large v-if="notification.type === 'warning'" class="mr-2">{{
          navigationIcons.warning
        }}</v-icon>
        <v-icon large v-if="notification.type === 'error'" class="mr-2">{{
          navigationIcons.error
        }}</v-icon>

        <!-- Notification Text -->
        {{ notification.text }}
      </v-layout>

      <!-- Close Button -->
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          @click="notification.displayed = false"
          icon
          v-bind="attrs"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Notification",
  computed: mapGetters(["allNotifications", "navigationIcons"]),
};
</script>
