import axios from "axios";
import { ObjectId } from "mongodb";
import { Sensor, SensorStatus } from "../../../types/sensor";

const url = "api/simulation/sensor/";

const state = {
  sim_sensors: [],
};

const getters = {
  /**
   * Title: allSimSensors()
   *
   * Description: Returns all SimSensors as an array
   */
  allSimSensors: (state: any) => state.sim_sensors,

  /**
   * Title: specificSimSensor()
   *
   * Description: Returns a specific SimSensor
   *
   * @param {ObjectId} sim_sensor_id the id of the sim_sensor that should be returned
   *
   */
  specificSimSensor: (state: any) => (sim_sensor_id: ObjectId) => {
    return state.sim_sensors.find(
      (sim_sensor: Sensor) => sim_sensor._id === sim_sensor_id
    );
  },
};

const actions = {
  /**
   * Title: fetchSimSensors()
   *
   * Route: GET /sim_sensor/
   * Description: Fetches data for all SimSensors
   */

  async fetchSimSensors({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url + "/all");
      commit("setSimSensors", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Sensoren konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newSimSensor()
   *
   * Route: Post /sim_sensor/
   * Description: Adds new SimSensor to the Database
   *
   * @param {String} name the name of new sim_sensor
   * @param {String} description the description of the new sim_sensor
   * @param {String} location the location of the new sim_sensor
   * @param {String} icon the icon of the new sim_sensor
   * @param {Number} operating_max_critical the critical max operating range
   * @param {Number} operating_min_critical the critical min operating range
   * @param {Number} operating_max_warn the max operating range
   * @param {Number} operating_min_warn the min operating range
   * @param {String} unit the unit used by the sim_sensor
   *
   * Info: Every other attribute of a sim_sensor is beeing added by the API and returned as a response which then is pushed to the state
   */
  async newSimSensor(
    { commit, dispatch }: any,
    {
      name,
      description,
      location,
      icon,
      operating_range_max,
      operating_range_min,
      optimal_operating_range_max,
      optimal_operating_range_min,
      unit,
    }: {
      name: string;
      description: string;
      location: string;
      icon: string;
      operating_range_max: number;
      operating_range_min: number;
      optimal_operating_range_max: number;
      optimal_operating_range_min: number;
      unit: string;
    }
  ) {
    try {
      const response = await axios.post(url, {
        name,
        description,
        location,
        icon,
        operating_range_max,
        operating_range_min,
        optimal_operating_range_max,
        optimal_operating_range_min,
        unit,
      });
      commit("addSimSensor", response.data);
      dispatch(
        "newNotification",
        {
          text: "Simulierter Sensor gespeichert",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierter Sensor konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: deleteSimSensor()
   *
   * Route: Delete /sim_sensor/id
   * Description: Deletes a SimSensor from the Database
   *
   * @param {SimSensor} sim_sensor the sim_sensor that is being deleted
   *
   */
  async deleteSimSensor({ commit, dispatch }: any, sim_sensor: Sensor) {
    try {
      const delete_url = url + sim_sensor._id;
      const response = await axios.delete(delete_url);
      commit("removeSimSensor", sim_sensor);
      dispatch(
        "newNotification",
        {
          text: "Simulierter Sensor wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierter Sensor konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editSimSensor()
   *
   * Route: Put /sim_sensor/id
   * Description: Pushes the new state of a sim_sensor to the databas
   *
   * @param {SimSensor} sim_sensor the new state sim_sensor that is being edited
   * @param {Number} sim_sensor_index the index of the machin within the state array, transmitted as the new state of the sim_sensor would not allow to find the index here as new state is different from the old state
   *
   * Info: Every other attribute of a sim_sensor is beeing added by the API and returned as a response which then is pushed to the state
   */
  async editSimSensor(
    { commit, dispatch }: any,
    {
      sim_sensor,
      sim_sensor_index,
    }: { sim_sensor: Sensor; sim_sensor_index: number }
  ) {
    try {
      console.log(sim_sensor);
      const put_url = url + sim_sensor._id;
      const response = await axios.put(put_url, sim_sensor);
      const data = response.data;
      console.log(data);
      console.log(sim_sensor_index);
      commit("updateSimSensor", { sim_sensor: data, sim_sensor_index });
      dispatch(
        "newNotification",
        {
          text: "Simulierte Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: startSimSensor()
   *
   * Description: trys to start a SimSensor
   *
   * @param {ObjectId} sim_sensor_id the id of the sim_sensor
   */
  // async startSimSensor({ dispatch }: any, { sim_sensor_id }: { sim_sensor_id: ObjectId }) {
  //   try {
  //     const patch_url = url + "command/" + sim_sensor_id;
  //     const response = await axios.patch(patch_url, { command: "start" });
  //     dispatch(
  //       "newNotification",
  //       {
  //         text: "SimSensor wurde gestartet.",
  //         type: "success",
  //         timeout: 3000,
  //       },
  //       { root: true }
  //     );
  //   } catch (error) {
  //     console.log(error);
  //     dispatch(
  //       "newNotification",
  //       {
  //         text: "SimSensor konnte nicht gestartet werden.",
  //         type: "error",
  //         timeout: 3000,
  //       },
  //       { root: true }
  //     );
  //   }
  // },

  /**
   * Title: stopSimSensor()
   *
   * Description: trys to stop a SimSensor
   *
   * @param {ObjectId} sim_sensor_id the id of the sim_sensor
   */
  // async stopSimSensor({ dispatch }: any, { sim_sensor_id }: { sim_sensor_id: ObjectId }) {
  //   try {
  //     const patch_url = url + "command/" + sim_sensor_id;
  //     const response = await axios.patch(patch_url, { command: "stop" });
  //     dispatch(
  //       "newNotification",
  //       {
  //         text: "SimSensor wurde gestopt.",
  //         type: "success",
  //         timeout: 3000,
  //       },
  //       { root: true }
  //     );
  //   } catch (error) {
  //     console.log(error);
  //     dispatch(
  //       "newNotification",
  //       {
  //         text: "SimSensor konnte nicht gestopt werden.",
  //         type: "error",
  //         timeout: 3000,
  //       },
  //       { root: true }
  //     );
  //   }
  // },

  /**
   * Title: mqttSimSensorStatusUpdate()
   *
   * Description: Updates the Status of a sim_sensor received by mqtt
   *
   * @param {SimSensor} sim_sensor_id the id of the sim_sensor
   * @param {Number} sim_sensor_status the status of the sim_sensor
   */
  mqttSimSensorStatusUpdate(
    { commit, getters }: any,
    {
      sim_sensor_id,
      sim_sensor_status,
    }: { sim_sensor_id: ObjectId; sim_sensor_status: SensorStatus }
  ) {
    const sim_sensor_index = getters.allSimSensors.indexOf(
      getters.specificSimSensor(sim_sensor_id)
    );
    commit("updateSimSensorStatus", { sim_sensor_index, sim_sensor_status });
  },

  /**
   * Title: mqttSimSensorValueUpdate()
   *
   * Description: Updates the Value of a sim_sensor received by mqtt
   *
   * @param {SimSensor} sim_sensor_id the id of the sim_sensor
   * @param {Number} sim_sensor_value the value of the sim_sensor
   */
  mqttSimSensorValueUpdate(
    { commit, getters }: any,
    {
      sim_sensor_id,
      sim_sensor_value,
    }: { sim_sensor_id: ObjectId; sim_sensor_value: SensorStatus }
  ) {
    const sim_sensor_index = getters.allSimSensors.indexOf(
      getters.specificSimSensor(sim_sensor_id)
    );
    commit("updateSimSensorValue", { sim_sensor_index, sim_sensor_value });
  },

  /**
   * Title: mqttSimSensorValueUpdate()
   *
   * Description: Updates the Value of a sim_sensor received by mqtt
   *
   * @param {SimSensor} sim_sensor_id the id of the sim_sensor
   * @param {Number} sim_sensor_value the value of the sim_sensor
   */
  mqttSimSensorOperatingTimeUpdate(
    { commit, getters }: any,
    {
      sim_sensor_id,
      operating_time,
    }: { sim_sensor_id: ObjectId; operating_time: number }
  ) {
    const sim_sensor_index = getters.allSimSensors.indexOf(
      getters.specificSimSensor(sim_sensor_id)
    );
    commit("updateSimSensorOperatingTime", {
      sim_sensor_index,
      operating_time,
    });
  },

  /**
   * Title: mqttSensorValueUpdate()
   *
   * Description: Updates the Value of a sensor received by mqtt
   *
   * @param {Sensor} sensor_id the id of the sensor
   * @param {Number} assigned the value of the sensor
   */
  mqttSimSensorAssignedUpdate(
    { commit, getters }: any,
    { sim_sensor_id, assigned }: { sim_sensor_id: ObjectId; assigned: boolean }
  ) {
    const sim_sensor_index = getters.allSimSensors.indexOf(
      getters.specificSimSensor(sim_sensor_id)
    );
    commit("updateSimSensorAssigned", { sim_sensor_index, assigned });
  },
};

const mutations = {
  setSimSensors: (state: any, sim_sensors: Sensor[]) =>
    (state.sim_sensors = sim_sensors),
  addSimSensor: (state: any, sim_sensor: Sensor) =>
    state.sim_sensors.push(sim_sensor),
  removeSimSensor: (state: any, sim_sensor: Sensor) =>
    state.sim_sensors.splice(state.sim_sensors.indexOf(sim_sensor), 1),
  updateSimSensor: (state: any, payload: any) =>
    (state.sim_sensors[payload.sim_sensor_index] = payload.sim_sensor),
  updateSimSensorStatus: (state: any, payload: any) =>
    (state.sim_sensors[payload.sim_sensor_index].current_status =
      payload.sim_sensor_status.valueOf()),
  updateSimSensorValue: (state: any, payload: any) => (
    (state.sim_sensors[payload.sim_sensor_index].current_reading =
      payload.sim_sensor_value.valueOf()),
    (state.sim_sensors[payload.sim_sensor_index].operating_time += 0.16)
  ),
  updateSimSensorOperatingTime: (state: any, payload: any) =>
    (state.sim_sensors[payload.sim_sensor_index].operating_time =
      payload.operating_time.valueOf()),
  updateSimSensorAssigned: (state: any, payload: any) =>
    (state.sim_sensors[payload.sim_sensor_index].assigned =
      payload.assigned.valueOf()),
  addToSimSensorMaintenanceHistory: (state: any, payload: any) =>
    state.sim_sensors[payload.sim_sensor_index].maintenance_history.push(
      payload.maintenance_id
    ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
