import store from "../vuex-store/index";

/**
 * Title: processFactoryMessage
 *
 * Description: processes MQTT Sensor Messages and calls according state management functions
 *
 * @param id The ID of the Factory that is supposed to be changed
 * @param parameter The Paramter that is supposed to be changed
 * @param message Message of the MQTT-Message
 */
export function processFactoryMessage(
  id: string,
  parameter: string,
  message: Buffer
) {
  //console.log("[MQTT - Factory] " + parameter + " | " + message);
  if (parameter === "status") {
    store.dispatch("mqttFactoryStatusUpdate", {
      factory_id: id,
      current_status: Number.parseFloat(message.toString()),
    });
  } else if (parameter === "current_throughput") {
    store.dispatch("mqttFactoryCurrentThroughputUpdate", {
      factory_id: id,
      current_throughput: Number.parseFloat(message.toString()),
    });
  }
}
