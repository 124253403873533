import axios from "axios";
import { ObjectId } from "mongodb";
import { MachineType} from "../../types/machineType";

const url = "api/machineType/";

const state = {
  machine_types: [],
};

const getters = {
  /**
   * Title: allMachineTypes()
   *
   * Description: Returns all MachineTypess as an array
   */
  allMachineTypes: (state: any):MachineType[] => state.machine_types,

  /**
   * Title: specificMachineType()
   *
   * Description: Returns a specific MachineType
   * 
   * @param {ObjectId} machine_type_id the id of the machinetype that should be returned
   * 
   */
  specificMachineType: (state: any) => (machine_type_id: ObjectId): MachineType => {
    return state.machine_types.find(
      (machine_type: MachineType) => machine_type._id === machine_type_id
    );
  },
};

const actions = {
  /**
   * Title: fetchMachineTypes()
   *
   * Route: GET /machineType/
   * Description: Fetches data for all MachineTypes
   */

  async fetchMachineTypes({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setMachineTypes", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        'newNotification',
        {
          text: "Maschinentypen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        {root:true}
      );
    }
  },

  /**
   * Title: newMachineType()
   *
   * Route: Post /machineType/
   * Description: Adds a new machinetype to the Database
   *
   * @param {String} name the name of new machinetype
   * @param {String} description the description of the new machinetype
   * @param {String} icon the icon of the new machinetype
   *
   * Info: Every other attribute of a machine is beeing added by the API and returned as a response which then is pushed to the state
   */

  async newMachineType(
    { commit, dispatch }: any,
    { name, description, icon }: { name: string, description: string, icon: string }
  ) {

    try {
      const response = await axios.post(url, {
      name,
      description,
      icon,
      assemblyQueue: [],
      maintenance_plan: [],
      });
      commit('addMachineType', response.data);

      dispatch(
        'newNotification',
        {
          text: "Maschinentyp gespeichert.",
          type: "success",
          timeout: 3000,
        },
        {root:true}
      );
    } catch (error) {
      console.log(error);
      dispatch(
        'newNotification',
        {
          text: "Maschinentyp konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        {root:true}
      );
    }
  },

  /**
   * Title: deleteMachineType()
   *
   * Route: Delete /machineType/id
   * Description: Deletes machinetype from the Database
   *
   * @param {MachineType} machine_type the name of new machinetype
   *
   */
  async deleteMachineType({ commit, dispatch }: any, machine_type: MachineType) {
    try {
      //const delete_url = url + machine._id
      //const response = await axios.delete(delete_url);
      commit("removeMachineType", machine_type);
      dispatch(
        'newNotification',
        {
          text: "Maschinentyp wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        {root:true}
      );
    } catch (error) {
      console.log(error);
      dispatch(
        'newNotification',
        {
          text: "Maschinentyp konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        {root:true}
      );
    }
  },

  /**
   * Title: editMachineType()
   *
   * Route: Post /machineType/id
   * Description: Pushes the new state of a machine to the databas
   *
   * @param {Machine} machine_type the new state of the machinetype that is being edited
   * @param {Number} machine_type_index the index of the machin within the state array, transmitted as the new state of the machinetype would not allow to find the index here as new state is different from the old state
   *
   */
  async editMachineType({ commit, dispatch }: any, { machine_type, machine_type_index }: any) {
    try {
      console.log(machine_type)
      const put_url = url + machine_type._id
      const response = await axios.put(put_url, machine_type);
      const data = response.data
      commit("updateMachineType", { machine_type: data, machine_type_index });
      //commit("updateMachineType", { machine_type, machine_type_index });
      dispatch(
        'newNotification',
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        {root:true}
      );
    } catch (error) {
      console.log(error);
      dispatch(
        'newNotification',
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        {root:true}
      );
    }
  },
};

const mutations = {
  setMachineTypes: (state: any, machine_types: MachineType[]) => (state.machine_types = machine_types),
  addMachineType: (state: any, machine_type: MachineType) => state.machine_types.push(machine_type),
  removeMachineType: (state: any, machine_type: MachineType) =>
    state.machine_types.splice(state.machine_types.indexOf(machine_type), 1),
  updateMachineType: (state: any, payload: any) =>
    (state.machine_types[payload.machine_type_index] = payload.machine_type),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
