import axios from "axios";
import { ObjectId } from "mongodb";
import { MaterialType } from "../../types/materialType";

const url = "api/materialType/";

const state = {
  material_types: [],
};

const getters = {
  /**
   * Title: allMaterialTypes()
   *
   * Description: Returns all MaterialTypess as an array
   */
  allMaterialTypes: (state: any): MaterialType[] => state.material_types,

  /**
   * Title: specificMaterialType()
   *
   * Description: Returns a specific MaterialType
   *
   * @param {ObjectId} material_type_id the id of the materialtype that should be returned
   *
   */
  specificMaterialType:
    (state: any) =>
    (material_type_id: ObjectId): MaterialType => {
      return state.material_types.find(
        (material_type: MaterialType) => material_type._id === material_type_id
      );
    },
};

const actions = {
  /**
   * Title: fetchMaterialTypes()
   *
   * Route: GET /materialType/
   * Description: Fetches data for all MaterialTypes
   */

  async fetchMaterialTypes({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setMaterialTypes", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Materialtypen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newMaterialType()
   *
   * Route: Post /materialType/
   * Description: Adds a new materialtype to the Database
   *
   * @param {String} name the name of new materialtype
   * @param {String} description the description of the new materialtype
   * @param {String} icon the icon of the new materialtype
   *
   * Info: Every other attribute of a material is beeing added by the API and returned as a response which then is pushed to the state
   */

  async newMaterialType(
    { commit, dispatch }: any,
    {
      name,
      description,
      icon,
    }: { name: string; description: string; icon: string }
  ) {
    try {
      const response = await axios.post(url, {
        name,
        description,
        icon,
      });
      commit("addMaterialType", response.data);

      dispatch(
        "newNotification",
        {
          text: "Materialtyp gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Materialtyp konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: deleteMaterialType()
   *
   * Route: Delete /materialType/id
   * Description: Deletes materialtype from the Database
   *
   * @param {MaterialType} material_type the name of new materialtype
   *
   */
  async deleteMaterialType(
    { commit, dispatch }: any,
    material_type: MaterialType
  ) {
    try {
      //const delete_url = url + material._id
      //const response = await axios.delete(delete_url);
      commit("removeMaterialType", material_type);
      dispatch(
        "newNotification",
        {
          text: "Materialtyp wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Materialtyp konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editMaterialType()
   *
   * Route: Post /materialType/id
   * Description: Pushes the new state of a material to the databas
   *
   * @param {Material} material_type the new state of the materialtype that is being edited
   * @param {Number} material_type_index the index of the machin within the state array, transmitted as the new state of the materialtype would not allow to find the index here as new state is different from the old state
   *
   */
  async editMaterialType(
    { commit, dispatch }: any,
    { material_type, material_type_index }: any
  ) {
    try {
      const put_url = url + material_type._id;
      const response = await axios.put(put_url, material_type);
      const data = response.data;
      commit("updateMaterialType", {
        material_type: data,
        material_type_index,
      });
      //commit("updateMaterialType", { material_type, material_type_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  mqttMaterialTypeInStockUpdate(
    { commit, getters }: any,
    {
      material_type_id,
      in_stock,
    }: { material_type_id: ObjectId; in_stock: number }
  ) {
    const material_type_index = getters.allMaterialTypes.indexOf(
      getters.specificMaterialType(material_type_id)
    );
    commit("updateMaterialTypeInStock", { material_type_index, in_stock });
  },
};

const mutations = {
  setMaterialTypes: (state: any, material_types: MaterialType[]) =>
    (state.material_types = material_types),
  addMaterialType: (state: any, material_type: MaterialType) =>
    state.material_types.push(material_type),
  removeMaterialType: (state: any, material_type: MaterialType) =>
    state.material_types.splice(state.material_types.indexOf(material_type), 1),
  updateMaterialType: (state: any, payload: any) =>
    (state.material_types[payload.material_type_index] = payload.material_type),
  updateMaterialTypeInStock: (state: any, payload: any) =>
    (state.material_types[payload.material_type_index].in_stock =
      payload.in_stock.valueOf()),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
