var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Neues Passwort für "+_vm._s(_vm.edited_user.login))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",model:{value:(_vm.form_validation),callback:function ($$v) {_vm.form_validation=$$v},expression:"form_validation"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Passwort","type":_vm.show_pw ? 'text' : 'password',"append-icon":_vm.show_pw ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                  function () { return !!_vm.new_password || 'Pflichtfeld'; },
                  function () { return (!!_vm.new_password && _vm.new_password.length >= 12) ||
                    'Mindestens 12 Zeichen!'; } ],"required":""},on:{"click:append":function($event){_vm.show_pw = !_vm.show_pw}},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Passwort bestätigen","type":_vm.show_pw ? 'text' : 'password',"append-icon":_vm.show_pw ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                  function () { return !!_vm.new_password_confirm || 'Pflichtfeld'; },
                  function () { return (!!_vm.new_password_confirm &&
                      _vm.new_password_confirm.length >= 12) ||
                    'Mindestens 12 Zeichen!'; } ],"required":""},on:{"click:append":function($event){_vm.show_pw = !_vm.show_pw}},model:{value:(_vm.new_password_confirm),callback:function ($$v) {_vm.new_password_confirm=$$v},expression:"new_password_confirm"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.$emit('closeEditPasswordDialog');
          _vm.$refs.form.reset();}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"green","text":"","disabled":!_vm.form_validation},on:{"click":_vm.saveNewPassword}},[_vm._v(" Speichern ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }