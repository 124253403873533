











































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Notification from "./components/general/Notification.vue";
import UserDialog from "./components/general/UserDialog.vue";
import LoginDialog from "./components/general/LoginDialog.vue";
import NotificationDialog from "./components/general/NotificationDialog.vue";
import { connectToMqttBroker } from "./mqtt/index";
import { getMaintenanceMessages } from "./services/maintenanceService";

export default Vue.extend({
  name: "App",

  components: {
    Notification,
    UserDialog,
    LoginDialog,
    NotificationDialog,
  },

  computed: {
    ...mapGetters([
      "getAppLoadingStatus",
      "getAppLoginStatus",
      "navigationIcons",
      "getAppLoginRights",
      "allNotifications",
    ]),
  },

  watch: {
    getAppLoginStatus: function () {
      if (this.getAppLoginStatus === true) {
        this.loadData();
      }
    },
  },

  data: () => ({
    display_user_dialog: false,
    display_notification_dialog: false,
    display_new_maintenance_dialog: false,
  }),

  async created() {
    await this.fetchRoles();
    await this.fetchUsers();
    // await this.logIn({
    //   _id: "621d43fac49e49629ef7de9f",
    //   login: "root",
    //   first_name: "root",
    //   last_name: "User",
    //   password: "root",
    //   created_by: "621d43fac49e49629ef7de9f",
    //   role: "621d4382c49e49629ef7de9e",
    //   creation_date: "2022-02-28T21:51:54.324Z",
    //   last_login: "2022-02-28T21:51:54.324Z",
    //   status: 1,
    // });
  },

  methods: {
    ...mapActions([
      "logIn",
      "updateOverviewActiveElement",
      "fetchMachines",
      "fetchMachineTypes",
      "fetchSensors",
      "fetchFactorys",
      "fetchMachineGroups",
      "changeAppLoadingStatus",
      "fetchRoles",
      "fetchUsers",
      "fetchMaterialTypes",
      "fetchAssemblyTypes",
      "fetchAssemblys",
      "fetchProductionOrders",
      "logOut",
      "fetchSensorTypes",
      "fetchMaintenanceTypes",
      "fetchMaintenances",
      "fetchSimulations",
      "fetchSimFactorys",
      "fetchSimMachineGroups",
      "fetchSimMachines",
      "fetchSimSensors",
      "fetchSimAssemblys",
      "fetchOverviewNavigationTree",
    ]),

    async loadData() {
        await this.changeAppLoadingStatus(true);
        
        //Fetcht Types of Digital Model
        await this.fetchMachineTypes();
        await this.fetchMaterialTypes();
        await this.fetchAssemblyTypes();
        await this.fetchSensorTypes();
        await this.fetchMaintenanceTypes();

        // Fetch Digital Model
        await this.fetchFactorys();
        await this.fetchMachineGroups();
        await this.fetchMachines();
        await this.fetchSensors();
        await this.fetchAssemblys();
        await this.fetchProductionOrders();
        await this.fetchMaintenances();
        await this.fetchOverviewNavigationTree();

        //Fetch Simulation Data
        await this.fetchSimulations();
        await this.fetchSimFactorys();
        await this.fetchSimMachineGroups();
        await this.fetchSimMachines();
        await this.fetchSimSensors();
        await this.fetchSimAssemblys();

        //Fetch Messages
        await getMaintenanceMessages();

        //Connect to MQTT
        await connectToMqttBroker();
        this.changeAppLoadingStatus(false);
    },
  },
});
