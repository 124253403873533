import axios from "axios";
import { ObjectId } from "mongodb";
import { Machine, MachineStatus } from "../../../types/machine";

const url = "api/simulation/machine/";

const state = {
  sim_machines: [],
};

const getters = {
  /**
   * Title: allSimMachines()
   *
   * Description: Returns all SimMachines as an array
   */
  allSimMachines: (state: any): Machine[] => state.sim_machines,

  /**
   * Title: specificSimMachine()
   *
   * Description: Returns a specific SimMachine
   *
   * @param {ObjectId} sim_machine_id the id of the sim_machine that should be returned
   *
   */
  specificSimMachine:
    (state: any) =>
    (sim_machine_id: ObjectId): Machine => {
      return state.sim_machines.find(
        (sim_machine: Machine) => sim_machine._id === sim_machine_id
      );
    },
};

const actions = {
  /**
   * Title: fetchSimMachines()
   *
   * Route: GET /sim_machine
   * Description: Fetches data for all SimMachines
   */

  async fetchSimMachines({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url + "/all");
      commit("setSimMachines", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Maschinen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newSimMachine()
   *
   * Route: Post /sim_machine/
   * Description: Adds new SimMachine to the Database
   *
   * @param {String} name the name of new sim_machine
   * @param {String} description the description of the new sim_machine
   * @param {String} location the location of the new sim_machine
   * @param {ObjectId} type the type of the new sim_machine
   *
   * Info: Every other attribute of a sim_machine is beeing added by the API and returned as a response which then is pushed to the state
   */
  async newSimMachine(
    { commit, dispatch }: any,
    {
      name,
      description,
      location,
      type,
    }: { name: string; description: string; location: string; type: ObjectId }
  ) {
    try {
      const response = await axios.post(url, {
        name,
        description,
        location,
        type,
        components: {
          sensors: [],
        },
      });
      commit("addSimMachine", response.data);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Maschine gespeichert",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Maschine konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newSimMachine()
   *
   * Route: Delete /sim_machine/id
   * Description: Deletes a SimMachine from the Database
   *
   * @param {SimMachine} sim_machine the sim_machine that is being deleted
   *
   */
  async deleteSimMachine({ commit, dispatch }: any, sim_machine: Machine) {
    try {
      const delete_url = url + sim_machine._id;
      const response = await axios.delete(delete_url);
      commit("removeSimMachine", sim_machine);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Maschine wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Maschine konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editSimMachine()
   *
   * Route: Put /sim_machine/id
   * Description: Pushes the new state of a sim_machine to the databas
   *
   * @param {SimMachine} sim_machine the new state sim_machine that is being edited
   * @param {Number} sim_machine_index the index of the machin within the state array, transmitted as the new state of the sim_machine would not allow to find the index here as new state is different from the old state
   *
   * Info: Every other attribute of a sim_machine is beeing added by the API and returned as a response which then is pushed to the state
   */

  async editSimMachine(
    { commit, dispatch }: any,
    {
      sim_machine,
      sim_machine_index,
    }: { sim_machine: Machine; sim_machine_index: number }
  ) {
    try {
      const put_url = url + sim_machine._id;
      const response = await axios.put(put_url, sim_machine);
      const data = response.data;
      commit("updateSimMachine", { sim_machine: data, sim_machine_index });
      dispatch(
        "newNotification",
        {
          text: "Simulierte Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: addSimSensorToSimMachine()
   *
   * Route: Put /sim_machine/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} sim_machine_id the id of the sim_machine that is being edited
   * @param {Number} sim_sensor_id the id of the sim_sensor that is beeing added
   *
   */

  async addSimSensorToSimMachine(
    { commit, dispatch, getters }: any,
    {
      sim_machine_id,
      sim_sensor_id,
    }: { sim_machine_id: ObjectId; sim_sensor_id: ObjectId }
  ) {
    try {
      const patch_url = url + sim_machine_id + "/addSensor";
      const sim_machine_index = getters.allSimMachines.indexOf(
        getters.specificSimMachine(sim_machine_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sim_sensor_id,
      });
      const data = response.data;
      commit("updateSimMachine", { sim_machine: data, sim_machine_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: removeSimSensorFromSimMachine()
   *
   * Route: Put /machien/id
   * Description: Adds a new SimSensor to a sim_machine
   *
   * @param {Factory} sim_machine_id the id of the sim_machine that is being edited
   * @param {Number} sim_sensor_id the id of the sim_sensor that is beeing added
   *
   */

  async removeSimSensorFromSimMachine(
    { commit, dispatch, getters }: any,
    {
      sim_machine_id,
      sim_sensor_id,
    }: { sim_machine_id: ObjectId; sim_sensor_id: ObjectId }
  ) {
    try {
      const patch_url = url + sim_machine_id + "/removeSensor";
      const sim_machine_index = getters.allSimMachines.indexOf(
        getters.specificSimMachine(sim_machine_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sim_sensor_id,
      });
      const data = response.data;
      commit("updateSimMachine", { sim_machine: data, sim_machine_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: startSimMachine()
   *
   * Description: trys to start a SimMachine
   *
   * @param {ObjectId} sim_machine_id the id of the sim_machine
   */
  // async startSimMachine(
  //   { dispatch }: any,
  //   { sim_machine_id }: { sim_machine_id: ObjectId }
  // ) {
  //   try {
  //     const patch_url = url + "command/" + sim_machine_id;
  //     const response = await axios.patch(patch_url, { command: "start" });
  //     dispatch(
  //       "newNotification",
  //       {
  //         text: "Maschine wurde gestartet.",
  //         type: "success",
  //         timeout: 3000,
  //       },
  //       { root: true }
  //     );
  //   } catch (error) {
  //     console.log(error);
  //     dispatch(
  //       "newNotification",
  //       {
  //         text: "Maschine konnte nicht gestartet werden.",
  //         type: "error",
  //         timeout: 3000,
  //       },
  //       { root: true }
  //     );
  //   }
  // },

  /**
   * Title: stopSimMachine()
   *
   * Description: trys to stop a SimMachine
   *
   * @param {ObjectId} sim_machine_id the id of the sim_machine
   */
  // async stopSimMachine(
  //   { dispatch }: any,
  //   { sim_machine_id }: { sim_machine_id: ObjectId }
  // ) {
  //   try {
  //     const patch_url = url + "command/" + sim_machine_id;
  //     const response = await axios.patch(patch_url, { command: "stop" });
  //     dispatch(
  //       "newNotification",
  //       {
  //         text: "Maschine wurde gestopt.",
  //         type: "success",
  //         timeout: 3000,
  //       },
  //       { root: true }
  //     );
  //   } catch (error) {
  //     console.log(error);
  //     dispatch(
  //       "newNotification",
  //       {
  //         text: "Maschine konnte nicht gestopt werden.",
  //         type: "error",
  //         timeout: 3000,
  //       },
  //       { root: true }
  //     );
  //   }
  // },

  /**
   * Title: mqttSimMachineStatusUpdate()
   *
   * Description: Updates the Status of a sim_machine received by mqtt
   *
   * @param {ObjectId} sim_machine_id the id of the sim_machine
   * @param {Number} sim_machine_status the status of the sim_machine
   */
  mqttSimMachineStatusUpdate(
    { commit, getters }: any,
    {
      sim_machine_id,
      sim_machine_status,
    }: { sim_machine_id: ObjectId; sim_machine_status: MachineStatus }
  ) {
    const sim_machine_index = getters.allSimMachines.indexOf(
      getters.specificSimMachine(sim_machine_id)
    );
    commit("setSimMachineStatus", { sim_machine_index, sim_machine_status });
  },

  /**
   * Title: mqttSimMachineStatusUpdate()
   *
   * Description: Updates the Status of a sim_machine received by mqtt
   *
   * @param {ObjectId} sim_machine_id the id of the sim_machine
   * @param {Number} sim_machine_status the status of the sim_machine
   */
  mqttSimMachineCurrentlyWorkingOnUpdate(
    { commit, getters }: any,
    {
      sim_machine_id,
      currently_working_on,
    }: { sim_machine_id: ObjectId; currently_working_on: ObjectId }
  ) {
    const sim_machine_index = getters.allSimMachines.indexOf(
      getters.specificSimMachine(sim_machine_id)
    );
    commit("setSimMachineCurrentlyWorkingOn", {
      sim_machine_index,
      currently_working_on,
    });
  },

  /**
   * Title: mqttSimMachineOperatingTimeUpdate()
   *
   * Description: Updates the Status of a sim_machine received by mqtt
   *
   * @param {ObjectId} sim_machine_id the id of the sim_machine
   * @param {Number} sim_machine_status the status of the sim_machine
   */
  mqttSimMachineOperatingTimeUpdate(
    { commit, getters }: any,
    {
      sim_machine_id,
      operating_time,
    }: { sim_machine_id: ObjectId; operating_time: number }
  ) {
    const sim_machine_index = getters.allSimMachines.indexOf(
      getters.specificSimMachine(sim_machine_id)
    );
    commit("setSimMachineOperatingTime", { sim_machine_index, operating_time });
  },

  /**
   * Title: mqttSensorValueUpdate()
   *
   * Description: Updates the Value of a sensor received by mqtt
   *
   * @param {Sensor} sensor_id the id of the sensor
   * @param {Number} assigned the value of the sensor
   */
  mqttSimMachineAssignedUpdate(
    { commit, getters }: any,
    {
      sim_machine_id,
      assigned,
    }: { sim_machine_id: ObjectId; assigned: boolean }
  ) {
    const sim_machine_index = getters.allSimMachines.indexOf(
      getters.specificSimMachine(sim_machine_id)
    );
    commit("updateSimMachineAssigned", { sim_machine_index, assigned });
  },
};

const mutations = {
  setSimMachines: (state: any, sim_machines: Machine[]) =>
    (state.sim_machines = sim_machines),
  addSimMachine: (state: any, sim_machine: Machine) =>
    state.sim_machines.push(sim_machine),
  removeSimMachine: (state: any, sim_machine: Machine) =>
    state.sim_machines.splice(state.sim_machines.indexOf(sim_machine), 1),
  updateSimMachine: (state: any, payload: any) =>
    (state.sim_machines[payload.sim_machine_index] = payload.sim_machine),
  setSimMachineCurrentlyWorkingOn: (state: any, payload: any) =>
    (state.sim_machines[payload.sim_machine_index].currently_working_on =
      payload.currently_working_on.valueOf()),
  setSimMachineStatus: (state: any, payload: any) =>
    (state.sim_machines[payload.sim_machine_index].current_status =
      payload.sim_machine_status.valueOf()),
  setSimMachineOperatingTime: (state: any, payload: any) =>
    (state.sim_machines[payload.sim_machine_index].operating_time =
      payload.operating_time.valueOf()),
  updateSimMachineAssigned: (state: any, payload: any) =>
    (state.sim_machines[payload.sim_machine_index].assigned =
      payload.assigned.valueOf()),
  addToSimMachineMaintenanceHistory: (state: any, payload: any) =>
    state.sim_machines[payload.sim_machine_index].maintenance_history.push(
      payload.maintenance_id
    ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
