import { ObjectId } from "mongodb";

export enum UserStatus {
    locked = 0,
    unlocked = 1,
  }

export type User = {
  _id: ObjectId;
  login: string;
  first_name: string;
  last_name: string;
  password: string;
  creation_date: Date;
  created_by: ObjectId;
  role: ObjectId;
  last_login: Date;
  status: UserStatus;
};