import * as dotenv from "dotenv";
dotenv.config({ path: "../.env" });
import * as mqtt from "mqtt";
import { processSensorMessage } from "./sensors.mqtt";
import { processMachineMessage } from "./machines.mqtt";
import { processAssemblyMessage } from "./assembly.mqtt";
import { processProductionOrderMessage } from "./productionOrder.mqtt";
import { processMaterialTypeMessage } from "./materialType.mqtt";
import { processMachineGroupMessage } from "./machineGroup.mqtt";
import { processFactoryMessage } from "./factory.mqtt";
import { processMaintenanceMessage } from "./maintenances.mqtt";
import {
  processSimulationMessage,
  processSimMachineGroupMessage,
  processSimMachineMessage,
  processSimSensorMessage,
} from "./simulation.mqtt";

// Global Variables
export let mqttClient: mqtt.Client;

// MQTT-Broker Connection configuration
const brokerUrl = process.env.VUE_APP_MQTT_BROKER_CONN_STRING;
const brokerOptions = {
    username: process.env.VUE_APP_MQTT_BROKER_USERNAME,
    password: process.env.VUE_APP_MQTT_BROKER_PASSWORD
};

/**
 * Initializes Connection to MQTT-Broker
 */
export function connectToMqttBroker() {

  // Connect to Broker
  mqttClient = mqtt.connect(brokerUrl, brokerOptions);

  // Subscribe to devices when connected
  mqttClient.on("connect", () => {
    mqttClient.subscribe(["frontend/#"]);
    console.log("[MQTT] Connected to Broker.");
  });

  // Listen for messages on subscribed topics
  mqttClient.on("message", (topic, message) => {
    //console.log("[MQTT] - onMessage");
    // Split topic
    const splittedTopic: string[] = topic.split("/");
    // [0] = frontend
    // [1] = type
    // [2] = id
    // [3] = prameter

    // Forward message to sub-service for further processing
    switch (splittedTopic[1]) {
      case "machine":
        processMachineMessage(splittedTopic[2], splittedTopic[3], message);
        break;
      case "sensor":
        processSensorMessage(splittedTopic[2], splittedTopic[3], message);
        break;
      case "assembly":
        processAssemblyMessage(splittedTopic[2], splittedTopic[3], message);
        break;
      case "productionOrder":
        processProductionOrderMessage(
          splittedTopic[2],
          splittedTopic[3],
          message
        );
        break;
      case "materialType":
        processMaterialTypeMessage(splittedTopic[2], splittedTopic[3], message);
        break;
      case "machineGroup":
        processMachineGroupMessage(splittedTopic[2], splittedTopic[3], message);
        break;
      case "factory":
        processFactoryMessage(splittedTopic[2], splittedTopic[3], message);
        break;
      case "maintenance":
        processMaintenanceMessage(splittedTopic[2], splittedTopic[3], message);
        break;
      case "simulation":
        processSimulationMessage(splittedTopic[2], splittedTopic[3], message);
        break;
      case "sim_machinegroup":
        processSimMachineGroupMessage(
          splittedTopic[2],
          splittedTopic[3],
          message
        );
        break;
      case "sim_machine":
        processSimMachineMessage(splittedTopic[2], splittedTopic[3], message);
        break;
      case "sim_sensor":
        processSimSensorMessage(splittedTopic[2], splittedTopic[3], message);
        break;
      default:
        break;
    }
  });

  // Log Connection loss
  mqttClient.on("offline", () => {
    console.log("[MQTT] Disconnected from Broker.");
  });
}
