/* Title: MaintenanceService
 * Description: Fetches Due Maintenances when application is initialized
 */

import axios from "axios";
import store from "../vuex-store/index";

// Fetches due machine Maintenances
export async function getDueMachineMaintenances(): Promise<unknown> {
  const get_url = "api/machine/nextMaintenance";
  return new Promise((resolve, reject) => {
    axios
      .get(get_url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        store.dispatch("newNotification", {
          text: "Fällige Maschinen Wartungen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
          hierachy_level: 4,
          required_right: "maintenance",
        });
      });
  });
}

// Fetches Due Sensor Maintenances
export async function getDueSensorMaintenances(): Promise<unknown> {
  const get_url = "api/sensor/nextMaintenance/";
  return new Promise((resolve, reject) => {
    axios
      .get(get_url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        store.dispatch("newNotification", {
          text: "Fällige Sensor Wartungen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
          hierachy_level: 5,
          required_right: "maintenance",
        });
      });
  });
}

// Fetches overdue Machine Maintenances
export async function getOverdueMachineMaintenances(): Promise<unknown> {
  const get_url = "api/machine/dueMaintenance";
  return new Promise((resolve, reject) => {
    axios
      .get(get_url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        store.dispatch("newNotification", {
          text: "Fällige Maschinen Wartungen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
          hierachy_level: 4,
          required_right: "maintenance",
        });
      });
  });
}

// Fetches Overdue Sensor Maintenances
export async function getOverdueSensorMaintenances(): Promise<unknown> {
  const get_url = "api/sensor/dueMaintenance/";
  return new Promise((resolve, reject) => {
    axios
      .get(get_url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        store.dispatch("newNotification", {
          text: "Fällige Sensor Wartungen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
          hierachy_level: 5,
          required_right: "maintenance",
        });
      });
  });
}

// Dispatches Notifcations based on fetched due/overdue Maintenances
export async function getMaintenanceMessages() {
  const due_sensor_maintenances = await getDueSensorMaintenances();
  const due_machine_maintenances = await getDueMachineMaintenances();
  const overdue_sensor_maintenances = await getOverdueSensorMaintenances();
  const overdue_machine_maintenances = await getOverdueMachineMaintenances();

  due_sensor_maintenances.forEach((element) => {
    store.dispatch("newNotification", {
      text:
        "[Sensor] - " +
        element.name +
        " muss in " +
        (element.due_time / 60).toFixed(1) +
        " Betriebsstunden gewartet werden!",
      type: "info",
      timeout: 5000,
      hierachy_level: 5,
      required_right: "maintenance",
    });
  });

  due_machine_maintenances.forEach((element) => {
    store.dispatch("newNotification", {
      text:
        "[Machine] - " +
        element.name +
        " muss in " +
        (element.due_time / 60).toFixed(1) +
        " Betriebsstunden gewartet werden!",
      type: "info",
      timeout: 5000,
      hierachy_level: 4,
      required_right: "maintenance",
    });
  });

  overdue_sensor_maintenances.forEach((element) => {
    store.dispatch("newNotification", {
      text:
        "[Sensor] - " +
        element.name +
        " hat eine seit " +
        (element.overdue_time / 60).toFixed(1) +
        " Betriebsstunden überfällige Wartung",
      type: "warning",
      timeout: 5000,
      hierachy_level: 5,
      required_right: "maintenance",
    });
  });

  overdue_machine_maintenances.forEach((element) => {
    store.dispatch("newNotification", {
      text:
        "[Maschine] - " +
        element.name +
        " hat eine seit " +
        (element.overdue_time / 60).toFixed(1) +
        " Betriebsstunden überfällige Wartung",
      type: "warning",
      timeout: 5000,
      hierachy_level: 4,
      required_right: "maintenance",
    });
  });
}
