import store from "../vuex-store/index";

/**
 * Title: processProductionOrderMessage
 *
 * Description: processes MQTT ProductionOrder Messages and calls according state management functions
 *
 * @param id The ID of the ProductionOrder that is supposed to be changed
 * @param parameter The Paramter that is supposed to be changed
 * @param message Message of the MQTT-Message
 */
export function processProductionOrderMessage(
  id: string,
  parameter: string,
  message: Buffer
) {
  //console.log("[MQTT - ProductionOrder] " + parameter + [" | "] + message);
  if (parameter === "status") {
    store.dispatch("mqttProductionOrderStatusUpdate", {
      production_order_id: id,
      production_order_status: Number.parseFloat(message.toString()),
    });

    if (Number.parseFloat(message.toString()) === 0) {
      const production_order_name =
        store.getters.specificProductionOrder(id).name;
      store.dispatch("newNotification", {
        text: 'Produktionsauftrag "' + production_order_name + '" fertiggestellt!',
        type: "info",
        timeout: 3000,
        hierachy_level: 5,
        required_right: 'production',
      });
    }
  }
}
