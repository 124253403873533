import { Notification } from "@/types/notifications";

const state = {
  notifications: [],
  checked_notifications: [],
};

const getters = {
  /**
   * Title: allNotifications()
   *
   * Description: Returns all Notifications as an array
   */
  allNotifications: (state: any): Notification[] => state.notifications,
  allCheckedNotifications: (state: any): Notification[] =>
    state.checked_notifications,
};

const actions = {
  /**
   * Title: newMachine()
   *
   * Route: Post /machine/
   * Description: Adds new Machine to the Database
   *
   * @param {String} text the text beeing displayed within the notification
   * @param {String} type the type of the notification, determins its color
   * @param {number} timeout the timout of the notification in milliseconds
   *
   */
  newNotification(
    { commit, rootGetters }: any,
    {
      text,
      type,
      timeout,
      hierachy_level,
      required_right,
    }: {
      text: string;
      type: "success" | "info" | "warning" | "error";
      timeout: number;
      hierachy_level: 1 | 2 | 3 | 4 | 5;
      required_right: string;
    }
  ): void {
    const notification: Notification = {
      id: Math.random(),
      date: new Date(),
      text,
      type,
      timeout,
      displayed: true,
    };
    if (required_right === undefined && hierachy_level === undefined) {
      commit("newNotification", notification);
    } else if (
      required_right === undefined &&
      rootGetters.getAppLoginRights.hierachy_level <= hierachy_level
    ) {
      commit("newNotification", notification);
    } else if (
      hierachy_level === undefined &&
      rootGetters.checkRights(required_right) === true
    ) {
      commit("newNotification", notification);
    } else if (
      rootGetters.getAppLoginRights.hierachy_level <= hierachy_level &&
      rootGetters.checkRights(required_right) === true
    ) {
      commit("newNotification", notification);
    }
  },

  checkNotification({ commit, getters }: any, notification: Notification) {
    const notification_index = getters.allNotifications.indexOf(notification);
    commit("spliceNotification", notification_index);
    commit("newCheckedNotification", notification);
  },
};

const mutations = {
  newNotification: (state: any, notification: Notification) =>
    state.notifications.unshift(notification),
  spliceNotification: (state: any, notification_index: number) =>
    state.notifications.splice(notification_index, 1),
  newCheckedNotification: (state: any, notification: Notification) =>
    state.checked_notifications.unshift(notification),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
