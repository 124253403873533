import axios from "axios";
import { ObjectId } from "mongodb";
import { ProductionOrder } from "../../types/productionOrder";

const url = "api/productionorder/";

const state = {
  productionorders: [],
};

const getters = {
  /**
   * Title: allProductionOrders()
   *
   * Description: Returns all ProductionOrders as an array
   */
  allProductionOrders: (state: any): ProductionOrder[] =>
    state.productionorders,

  /**
   * Title: specificProductionOrder()
   *
   * Description: Returns a specific ProductionOrder
   *
   * @param {ObjectId} productionorder_id the id of the productionorder that should be returned
   *
   */
  specificProductionOrder:
    (state: any) =>
    (productionorder_id: ObjectId): ProductionOrder => {
      return state.productionorders.find(
        (productionorder: ProductionOrder) =>
          productionorder._id === productionorder_id
      );
    },
};

const actions = {
  /**
   * Title: fetchProductionOrders()
   *
   * Route: GET /productionorder
   * Description: Fetches data for all ProductionOrders
   */

  async fetchProductionOrders({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setProductionOrders", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Produktionsaufträge konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newProductionOrder()
   *
   * Route: Post /productionorder/
   * Description: Adds new ProductionOrder to the Database
   *
   * @param {String} name the name of new productionorder
   * @param {String} description the description of the new productionorder
   * @param {String} location the location of the new productionorder
   * @param {ObjectId} type the type of the new productionorder
   *
   * Info: Every other attribute of a productionorder is beeing added by the API and returned as a response which then is pushed to the state
   */
  async newProductionOrder(
    { commit, dispatch, rootGetters }: any,
    {
      name,
      subassemblies,
    }: {
      name: string;
      subassemblies: { subassembly_type: ObjectId; amount: number }[];
    }
  ) {
    dispatch("changeAppLoadingStatus", true);
    try {
      console.log(name, subassemblies, rootGetters.getAppLoggedInUser._id);
      const response = await axios.post(url, {
        name,
        subassemblies,
        created_by: rootGetters.getAppLoggedInUser._id,
      });
      console.log(response.data);
      commit("addProductionOrder", response.data);
      dispatch("fetchAssemblys");
      dispatch("fetchMachineTypes");
      dispatch(
        "newNotification",
        {
          text: "Produktionsauftrag gespeichert",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Produktionsauftrag konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
    dispatch("changeAppLoadingStatus", false);
  },

  /**
   * Title: newProductionOrder()
   *
   * Route: Delete /productionorder/id
   * Description: Deletes a ProductionOrder from the Database
   *
   * @param {ProductionOrder} productionorder the productionorder that is being deleted
   *
   */
  async deleteProductionOrder(
    { commit, dispatch }: any,
    productionorder: ProductionOrder
  ) {
    dispatch("changeAppLoadingStatus", true);
    try {
      const delete_url = url + productionorder._id
      const response = await axios.delete(delete_url);
      commit("removeProductionOrder", productionorder);
      dispatch(
        "newNotification",
        {
          text: "Produktionsauftrag wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
      dispatch("fetchAssemblys");
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Produktionsauftrag konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
    dispatch("changeAppLoadingStatus", false);
  },

  /**
   * Title: mqttProductionOrderStatusUpdate()
   *
   * Description: Updates the Status of a production_order received by mqtt
   *
   * @param {Sensor} production_order_id the id of the production_order
   * @param {Number} production_order_status the status of the production_order
   */
  mqttProductionOrderStatusUpdate(
    { commit, getters }: any,
    {
      production_order_id,
      production_order_status,
    }: { production_order_id: ObjectId; production_order_status: number }
  ) {
    const production_order_index = getters.allProductionOrders.indexOf(
      getters.specificProductionOrder(production_order_id)
    );
    commit("setProductionOrderStatus", {
      production_order_index,
      production_order_status,
    });
  },
};

const mutations = {
  setProductionOrders: (state: any, productionorders: ProductionOrder[]) =>
    (state.productionorders = productionorders),
  addProductionOrder: (state: any, productionorder: ProductionOrder) =>
    state.productionorders.push(productionorder),
  removeProductionOrder: (state: any, productionorder: ProductionOrder) =>
    state.productionorders.splice(
      state.productionorders.indexOf(productionorder),
      1
    ),
  updateProductionOrder: (state: any, payload: any) =>
    Object.assign(
      state.productionorders[payload.productionorder_index],
      payload.productionorder
    ),
  setProductionOrderStatus: (state: any, payload: any) =>
    (state.productionorders[payload.production_order_index].status = payload.production_order_status.valueOf()),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
