import axios from "axios";
import { ObjectId } from "mongodb";
import { Factory } from "../../../types/factory";

const url = "api/simulation/factory/";

const state = {
  sim_factorys: [],
};

const getters = {
  /**
   * Title: allSimFactorys()
   *
   * Description: Returns all SimFactorys as an array
   */
  allSimFactorys: (state: any): Factory[] => state.sim_factorys,

  /**
   * Title: specificSimFactory()
   *
   * Description: Returns a specific SimFactory
   *
   * @param {ObjectId} sim_factory_id the id of the sim_factory that should be returned
   *
   */
  specificSimFactory:
    (state: any) =>
    (sim_factory_id: ObjectId): Factory => {
      return state.sim_factorys.find(
        (sim_factory: Factory) => sim_factory._id === sim_factory_id
      );
    },
};

const actions = {
  /**
   * Title: fetchSimFactorys()
   *
   * Route: GET /sim_factory
   * Description: Fetches data for all SimFactorys
   */

  async fetchSimFactorys({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url + "/all");
      commit("setSimFactorys", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Fabriken konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newSimFactory()
   *
   * Route: Post /sim_factory/
   * Description: Adds new SimFactory to the Database
   *
   * @param {String} name the name of new sim_factory
   * @param {String} description the description of the new sim_factory
   * @param {String} location the location of the new sim_factory
   * @param {ObjectId} type the type of the new sim_factory
   *
   * Info: Every other attribute of a sim_factory is beeing added by the API and returned as a response which then is pushed to the state
   */
  async newSimFactory(
    { commit, dispatch }: any,
    {
      name,
      description,
      location,
    }: { name: string; description: string; location: string }
  ) {
    try {
      const response = await axios.post(url, {
        name,
        description,
        location,
        components: {
          machine_groups: [],
          sensors: [],
        },
      });
      commit("addSimFactory", response.data);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Fabrik gespeichert",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Fabrik konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newSimFactory()
   *
   * Route: Delete /sim_factory/id
   * Description: Deletes a SimFactory from the Database
   *
   * @param {SimFactory} sim_factory the sim_factory that is being deleted
   *
   */
  async deleteSimFactory({ commit, dispatch }: any, sim_factory: Factory) {
    try {
      const delete_url = url + sim_factory._id;
      const response = await axios.delete(delete_url);
      commit("removeSimFactory", sim_factory);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Fabrik wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Fabrik konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editSimFactory()
   *
   * Route: Put /sim_factory/id
   * Description: Pushes the new state of a sim_factory to the databas
   *
   * @param {SimFactory} sim_factory the new state sim_factory that is being edited
   * @param {Number} sim_factory_index the index of the machin within the state array, transmitted as the new state of the sim_factory would not allow to find the index here as new state is different from the old state
   *
   * Info: Every other attribute of a sim_factory is beeing added by the API and returned as a response which then is pushed to the state
   */

  async editSimFactory(
    { commit, dispatch }: any,
    {
      sim_factory,
      sim_factory_index,
    }: { sim_factory: Factory; sim_factory_index: number }
  ) {
    try {
      const put_url = url + sim_factory._id;
      const response = await axios.put(put_url, sim_factory);
      const data = response.data;
      commit("updateSimFactory", { sim_factory: data, sim_factory_index });
      dispatch(
        "newNotification",
        {
          text: "Simulierte Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Simulierte Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: addSimMachineGroupToSimFactory()
   *
   * Route: Put /sim_factory/id
   * Description: Pushes the new state of a sim_factory to the databas
   *
   * @param {SimFactory} sim_factory_id the id sim_factory that is being edited
   * @param {Number} sim_machinegroup_id the id of the sim_machinegroup that is beeing added
   *
   */

  async addSimMachineGroupToSimFactory(
    { commit, dispatch, getters }: any,
    {
      sim_factory_id,
      sim_machinegroup_id,
    }: { sim_factory_id: ObjectId; sim_machinegroup_id: ObjectId }
  ) {
    try {
      const patch_url = url + sim_factory_id + "/addMachineGroup";
      const sim_factory_index = getters.allSimFactorys.indexOf(
        getters.specificSimFactory(sim_factory_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sim_machinegroup_id,
      });
      const data = response.data;
      commit("updateSimFactory", { sim_factory: data, sim_factory_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: removeSimMachineGroupToSimFactory()
   *
   * Route: Put /sim_factory/id
   * Description: Pushes the new state of a sim_factory to the databas
   *
   * @param {SimFactory} sim_factory_id the id sim_factory that is being edited
   * @param {Number} sim_machinegroup_id the id of the sim_machinegroup that is beeing added
   *
   */

  async removeSimMachineGroupFromSimFactory(
    { commit, dispatch, getters }: any,
    {
      sim_factory_id,
      sim_machinegroup_id,
    }: { sim_factory_id: ObjectId; sim_machinegroup_id: ObjectId }
  ) {
    try {
      const patch_url = url + sim_factory_id + "/removeMachineGroup";
      const sim_factory_index = getters.allSimFactorys.indexOf(
        getters.specificSimFactory(sim_factory_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sim_machinegroup_id,
      });
      const data = response.data;
      commit("updateSimFactory", { sim_factory: data, sim_factory_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: addSimSensorGroupToSimFactory()
   *
   * Route: Put /sim_factory/id
   * Description: Pushes the new state of a sim_factory to the databas
   *
   * @param {SimFactory} sim_factory_id the id sim_factory that is being edited
   * @param {Number} sim_sensor_id the id of the sim_machinegroup that is beeing added
   *
   */

  async addSimSensorToSimFactory(
    { commit, dispatch, getters }: any,
    {
      sim_factory_id,
      sim_sensor_id,
    }: { sim_factory_id: ObjectId; sim_sensor_id: ObjectId }
  ) {
    try {
      const patch_url = url + sim_factory_id + "/addSensor";
      const sim_factory_index = getters.allSimFactorys.indexOf(
        getters.specificSimFactory(sim_factory_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sim_sensor_id,
      });
      const data = response.data;
      commit("updateSimFactory", { sim_factory: data, sim_factory_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: addSimSensorGroupToSimFactory()
   *
   * Route: Put /sim_factory/id
   * Description: Pushes the new state of a sim_factory to the databas
   *
   * @param {SimFactory} sim_factory_id the id sim_factory that is being edited
   * @param {Number} sim_sensor_id the id of the sim_machinegroup that is beeing added
   *
   */

  async removeSimSensorFromSimFactory(
    { commit, dispatch, getters }: any,
    {
      sim_factory_id,
      sim_sensor_id,
    }: { sim_factory_id: ObjectId; sim_sensor_id: ObjectId }
  ) {
    try {
      const patch_url = url + sim_factory_id + "/removeSensor";
      const sim_factory_index = getters.allSimFactorys.indexOf(
        getters.specificSimFactory(sim_factory_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sim_sensor_id,
      });
      const data = response.data;
      commit("updateSimFactory", { sim_factory: data, sim_factory_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  mqttSimFactoryStatusUpdate(
    { commit, getters }: any,
    {
      sim_factory_id,
      current_status,
    }: { sim_factory_id: ObjectId; current_status: number }
  ) {
    const sim_factory_index = getters.allSimFactorys.indexOf(
      getters.specificSimFactory(sim_factory_id)
    );
    commit("setSimFactoryStatus", { sim_factory_index, current_status });
  },
};

const mutations = {
  setSimFactorys: (state: any, sim_factorys: Factory[]) =>
    (state.sim_factorys = sim_factorys),
  addSimFactory: (state: any, sim_factory: Factory) =>
    state.sim_factorys.push(sim_factory),
  removeSimFactory: (state: any, sim_factory: Factory) =>
    state.sim_factorys.splice(state.sim_factorys.indexOf(sim_factory), 1),
  updateSimFactory: (state: any, payload: any) =>
    (state.sim_factorys[payload.sim_factory_index] = payload.sim_factory),
  setSimFactoryStatus: (state: any, payload: any) =>
    (state.sim_factorys[payload.sim_factory_index].current_status =
      payload.current_status.valueOf()),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
