import Vuex from "vuex";
import Vue from "vue";

//import of Modules
import Notifications from "./modules/vuexNotifications";
import Navigation from "./modules/vuexNavigation";
import Machines from "./modules/vuexMachines";
import MachineTypes from "./modules/vuexMachineTypes";
import Sensors from "./modules/vuexSensors";
import Factory from "./modules/vuexFactory";
import MachineGroup from "./modules/vuexMachineGroup";
import App from "./modules/vuexApp";
import Roles from "./modules/vuexRoles";
import User from "./modules/vuexUser";
import MaterialTypes from "./modules/vuexMaterialTypes";
import AssemblyTypes from "./modules/vuexAssemblyTypes";
import Assembly from "./modules/vuexAssembly";
import ProductionOrder from "./modules/vuexProductionOrder";
import Overview from "./modules/vuexOverview";
import SensorTypes from "./modules/vuexSensorType";
import MaintenanceTypes from "./modules/vuexMaintenanceTypes";
import Maintenances from "./modules/vuexMaintenances";
import Simulations from "./modules/Simulation/vuexSimulation";
import SimFactory from "./modules/Simulation/vuexSimFactory";
import SimMachinGroup from "./modules/Simulation/vuexSimMachineGroup";
import SimMachine from "./modules/Simulation/vuexSimMachines";
import SimSensor from "./modules/Simulation/vuexSimSensors";
import SimAssembly from "./modules/Simulation/vuexSimAssembly";
import DevTools from "./modules/vuexDevTools";

//Load Vuex
Vue.use(Vuex);

//Create Store
export default new Vuex.Store({
  modules: {
    Notifications,
    Navigation,
    Machines,
    MachineTypes,
    Sensors,
    Factory,
    MachineGroup,
    App,
    Roles,
    User,
    MaterialTypes,
    AssemblyTypes,
    Assembly,
    ProductionOrder,
    Overview,
    SensorTypes,
    MaintenanceTypes,
    Maintenances,
    Simulations,
    SimFactory,
    SimMachinGroup,
    SimMachine,
    SimSensor,
    SimAssembly,
    DevTools,
  },
});
