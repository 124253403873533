import store from "../vuex-store/index";

/**
 * Title: processAssemblyMessage
 *
 * Description: processes MQTT Assembly Messages and calls according state management functions
 *
 * @param id The ID of the Assembly that is supposed to be changed
 * @param parameter The Paramter that is supposed to be changed
 * @param message Message of the MQTT-Message
 */
export function processMaterialTypeMessage(
  id: string,
  parameter: string,
  message: Buffer
) {
  //console.log("[MQTT - Assembly] " + parameter + [" | "] + message);
  if (parameter === "order") {
    const text =
      "Es wurde " +
      store.getters.specificMaterialType(id).name +
      " nachbestellt.";
    store.dispatch("newNotification", {
      text,
      type: "info",
      timeout: 3000,
    });
  } else if (parameter === "in_stock") {
    store.dispatch("mqttMaterialTypeInStockUpdate", {
      material_type_id: id,
      in_stock: Number.parseFloat(message.toString()),
    });
  }
}
