import axios from "axios";
import { ObjectId } from "mongodb";
import { MachineGroup } from "../../types/machinegroup";

const url = "api/machineGroup/";

const state = {
  machinegroups: [],
};

const getters = {
  /**
   * Title: allMachineGroups()
   *
   * Description: Returns all MachineGroups as an array
   */
  allMachineGroups: (state: any): MachineGroup[] => state.machinegroups,

  /**
   * Title: specificMachineGroup()
   *
   * Description: Returns a specific MachineGroup
   *
   * @param {ObjectId} machinegroup_id the id of the machinegroup that should be returned
   *
   */
  specificMachineGroup:
    (state: any) =>
    (machinegroup_id: ObjectId): MachineGroup => {
      return state.machinegroups.find(
        (machinegroup: MachineGroup) => machinegroup._id === machinegroup_id
      );
    },
};

const actions = {
  /**
   * Title: fetchMachineGroups()
   *
   * Route: GET /machinegroup
   * Description: Fetches data for all MachineGroups
   */

  async fetchMachineGroups({ commit, dispatch }: any) {
    try {
      const response = await axios.get(url);
      commit("setMachineGroups", response.data);
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Maschinengruppen konnten nicht geladen werden!",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newMachineGroup()
   *
   * Route: Post /machinegroup/
   * Description: Adds new MachineGroup to the Database
   *
   * @param {String} name the name of new machinegroup
   * @param {String} description the description of the new machinegroup
   * @param {String} location the location of the new machinegroup
   * @param {ObjectId} type the type of the new machinegroup
   *
   * Info: Every other attribute of a machinegroup is beeing added by the API and returned as a response which then is pushed to the state
   */
  async newMachineGroup(
    { commit, dispatch }: any,
    {
      name,
      description,
      location,
    }: { name: string; description: string; location: string }
  ) {
    try {
      const response = await axios.post(url, {
        name,
        description,
        location,
        components: {
          machines: [],
          sensors: [],
        },
      });
      commit("addMachineGroup", response.data);
      dispatch(
        "newNotification",
        {
          text: "Maschinengruppe gespeichert",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
      dispatch("fetchOverviewNavigationTree");
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Maschinengruppe konnte nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: newMachineGroup()
   *
   * Route: Delete /machinegroup/id
   * Description: Deletes a MachineGroup from the Database
   *
   * @param {MachineGroup} machinegroup the machinegroup that is being deleted
   *
   */
  async deleteMachineGroup(
    { commit, dispatch }: any,
    machinegroup: MachineGroup
  ) {
    try {
      // const delete_url = url + machinegroup._id
      // const response = await axios.delete(delete_url);
      commit("removeMachineGroup", machinegroup);
      dispatch(
        "newNotification",
        {
          text: "Maschinengruppe wurde erfolgreich gelöscht.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Maschinengruppe konnte nicht gelöscht werden",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: editMachineGroup()
   *
   * Route: Put /machinegroup/id
   * Description: Pushes the new state of a machinegroup to the databas
   *
   * @param {MachineGroup} machinegroup the new state machinegroup that is being edited
   * @param {Number} machinegroup_index the index of the machin within the state array, transmitted as the new state of the machinegroup would not allow to find the index here as new state is different from the old state
   *
   * Info: Every other attribute of a machinegroup is beeing added by the API and returned as a response which then is pushed to the state
   */

  async editMachineGroup(
    { commit, dispatch }: any,
    {
      machinegroup,
      machinegroup_index,
    }: { machinegroup: MachineGroup; machinegroup_index: number }
  ) {
    try {
      const put_url = url + machinegroup._id;
      const response = await axios.put(put_url, machinegroup);
      const data = response.data;
      commit("updateMachineGroup", { machinegroup: data, machinegroup_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
      dispatch("fetchOverviewNavigationTree");
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: addMachineGroupToFactory()
   *
   * Route: Put /factory/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} machinegroup_id the id machinegroup that is being edited
   * @param {Number} machine_id the id of the machine that is beeing added
   *
   */

  async addMachineToMachineGroup(
    { commit, dispatch, getters }: any,
    {
      machinegroup_id,
      machine_id,
    }: { machinegroup_id: ObjectId; machine_id: ObjectId }
  ) {
    try {
      const patch_url = url + machinegroup_id + "/addMachine";
      const machinegroup_index = getters.allMachineGroups.indexOf(
        getters.specificMachineGroup(machinegroup_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: machine_id,
      });
      const data = response.data;
      commit("updateMachineGroup", { machinegroup: data, machinegroup_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: removeMachineGroupToFactory()
   *
   * Route: Put /factory/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} factory_id the id factory that is being edited
   * @param {Number} machinegroup_id the id of the machinegroup that is beeing added
   *
   */

  async removeMachineFromMachineGroup(
    { commit, dispatch, getters }: any,
    {
      machinegroup_id,
      machine_id,
    }: { machine_id: ObjectId; machinegroup_id: ObjectId }
  ) {
    try {
      const patch_url = url + machinegroup_id + "/removeMachine";
      const machinegroup_index = getters.allMachineGroups.indexOf(
        getters.specificMachineGroup(machinegroup_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: machine_id,
      });
      const data = response.data;
      commit("updateMachineGroup", { machinegroup: data, machinegroup_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: addSensorGroupToFactory()
   *
   * Route: Put /factory/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} factory_id the id factory that is being edited
   * @param {Number} sensor_id the id of the machinegroup that is beeing added
   *
   */

  async addSensorToMachineGroup(
    { commit, dispatch, getters }: any,
    {
      machinegroup_id,
      sensor_id,
    }: { machinegroup_id: ObjectId; sensor_id: ObjectId }
  ) {
    try {
      const patch_url = url + machinegroup_id + "/addSensor";
      const machinegroup_index = getters.allMachineGroups.indexOf(
        getters.specificMachineGroup(machinegroup_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sensor_id,
      });
      const data = response.data;
      commit("updateMachineGroup", { machinegroup: data, machinegroup_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  /**
   * Title: addSensorGroupToFactory()
   *
   * Route: Put /factory/id
   * Description: Pushes the new state of a factory to the databas
   *
   * @param {Factory} factory_id the id factory that is being edited
   * @param {Number} sensor_id the id of the machinegroup that is beeing added
   *
   */

  async removeSensorFromMachineGroup(
    { commit, dispatch, getters }: any,
    {
      machinegroup_id,
      sensor_id,
    }: { machinegroup_id: ObjectId; sensor_id: ObjectId }
  ) {
    try {
      const patch_url = url + machinegroup_id + "/removeSensor";
      const machinegroup_index = getters.allMachineGroups.indexOf(
        getters.specificMachineGroup(machinegroup_id)
      );
      const response = await axios.patch(patch_url, {
        component_id: sensor_id,
      });
      const data = response.data;
      commit("updateMachineGroup", { machinegroup: data, machinegroup_index });
      dispatch(
        "newNotification",
        {
          text: "Änderungen wurden erfolgreich gespeichert.",
          type: "success",
          timeout: 3000,
        },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(
        "newNotification",
        {
          text: "Änderungen konnten nicht gespeichert werden.",
          type: "error",
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  mqttMachineGroupStatusUpdate(
    { commit, getters }: any,
    {
      machinegroup_id,
      current_status,
    }: { machinegroup_id: ObjectId; current_status: number }
  ) {
    const machinegroup_index = getters.allMachineGroups.indexOf(
      getters.specificMachineGroup(machinegroup_id)
    );
    commit("setMachineGroupStatus", { machinegroup_index, current_status });
  },

  /**
   * Title: mqttSensorValueUpdate()
   *
   * Description: Updates the Value of a sensor received by mqtt
   *
   * @param {Sensor} sensor_id the id of the sensor
   * @param {Number} assigned the value of the sensor
   */
  mqttMachineGroupAssignedUpdate(
    { commit, getters }: any,
    {
      machinegroup_id,
      assigned,
    }: { machinegroup_id: ObjectId; assigned: boolean }
  ) {
    const machinegroup_index = getters.allMachineGroups.indexOf(
      getters.specificMachineGroup(machinegroup_id)
    );
    commit("updateMachineGroupAssigned", { machinegroup_index, assigned });
  },

  mqttMachineGroupCurrentThroughputUpdate(
    { commit, getters }: any,
    {
      machinegroup_id,
      current_throughput,
    }: { machinegroup_id: ObjectId; current_throughput: number }
  ) {
    const machinegroup_index = getters.allMachineGroups.indexOf(
      getters.specificMachineGroup(machinegroup_id)
    );
    commit("setMachineGroupCurrentThroughput", {
      machinegroup_index,
      current_throughput,
    });
  },

  mqttMachineGroupAverageProcessingTimeUpdate(
    { commit, getters }: any,
    {
      machinegroup_id,
      average_processing_time,
    }: { machinegroup_id: ObjectId; average_processing_time: number }
  ) {
    const machinegroup_index = getters.allMachineGroups.indexOf(
      getters.specificMachineGroup(machinegroup_id)
    );
    commit("setMachineGroupAverageProcessingTime", {
      machinegroup_index,
      average_processing_time,
    });
  },
};

const mutations = {
  setMachineGroups: (state: any, machinegroups: MachineGroup[]) =>
    (state.machinegroups = machinegroups),
  addMachineGroup: (state: any, machinegroup: MachineGroup) =>
    state.machinegroups.push(machinegroup),
  removeMachineGroup: (state: any, machinegroup: MachineGroup) =>
    state.machinegroups.splice(state.machinegroups.indexOf(machinegroup), 1),
  updateMachineGroup: (state: any, payload: any) =>
    (state.machinegroups[payload.machinegroup_index] = payload.machinegroup),
  updateMachineGroupAssigned: (state: any, payload: any) =>
    (state.machinegroups[payload.machinegroup_index].assigned =
      payload.assigned.valueOf()),
  setMachineGroupStatus: (state: any, payload: any) => (
    state.machinegroups[payload.machinegroup_index].status_history.push({
      time: new Date().toISOString(),
      value: payload.current_status.valueOf(),
    }),
    (state.machinegroups[payload.machinegroup_index].current_status =
      payload.current_status.valueOf())
  ),
  setMachineGroupCurrentThroughput: (state: any, payload: any) => (
    state.machinegroups[payload.machinegroup_index].throughput_history.push({
      time: new Date().toISOString(),
      value: payload.current_throughput.valueOf(),
    }),
    (state.machinegroups[payload.machinegroup_index].current_throughput =
      payload.current_throughput.valueOf())
  ),
  setMachineGroupAverageProcessingTime: (state: any, payload: any) => (
    state.machinegroups[
      payload.machinegroup_index
    ].average_processing_time_history.push({
      time: new Date().toISOString(),
      value: payload.average_processing_time.valueOf(),
    }),
    (state.machinegroups[payload.machinegroup_index].average_processing_time =
      payload.average_processing_time.valueOf())
  ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
